import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import Footer from "../components/common/layout/Footer";
import Header from "../components/common/layout/Header";
import {Base_Url} from "./../utils/apiUrl"


import Disclaimer from "../components/common/layout/Disclaimer";
import Home from "../pages/Home";
import Hotline from "../components/common/layout/Hotline";
import ShareBtn from "../components/common/layout/ShareBtn";
import TradedStock from "../pages/Warrant/TradedStock";
import TradedWarrant from "../pages/Warrant/TradedWarrant";
import LiveMatrix from "../pages/WarrantTools/LiveMatrix";
import ClassicLiveMatrix from "../pages/WarrantTools/LiveMatrix/ClassicLiveMatrix";
import WarrantSearch from "../pages/WarrantTools/WarrantSearch";
import TurnOverDistribution from "../pages/Warrant/TurnOverDistribution";
import MoneyFlow from "../pages/Warrant/MoneyFlow";
import MoneyFlowChart from "../pages/Warrant/MoneyFlowChart"
import MarketTurnOver from "../pages/Warrant/MarketTurnOver";
import IndexFuturesRollover from "../pages/WarrantEducation/IndexFuturesRollover";
import WarrantTerms from "../pages/WarrantTools/WarrantTerms";
import Seminar from "../pages/WarrantEducation/Seminar";
import GainExposure from "../pages/WarrantEducation/GainExposure";
import FrequentlyAskedQues from "../pages/WarrantEducation/FrequentlyAskedQues";
import HowToStart from "../pages/WarrantEducation/HowToStart";
import YourFirstStep from "../pages/WarrantEducation/YourFirstStep";
import WarrantCalculator from "../pages/WarrantTools/warrantCalculator";
import WarrantTechnicalEvent from "../pages/WarrantTools/WarrantTechnicalEvent";
import WarrantUnderLyingChart from "../pages/WarrantTools/WarrantUnderLyingChart";
import WarrantDocument from "../pages/WarrantTools/WarrantDocument";
import WarrantHeatmap from "../pages/WarrantTools/WarrantHeatmap";
import WarrantComparison from "../pages/WarrantTools/WarrantComparison";
import SuccessSpeak from "../pages/WarrantEducation/SuccessSpeak";
import WarrantEducationVideo from "../pages/WarrantEducation/WarrantEducationVideo";
import WhyMacquarie from "../pages/WarrantEducation/WhyMacquarie";
import HowUseLiveMatrix from "../pages/WarrantEducation/HowUseLiveMatrix";
import IndexWarrant from "../pages/WarrantEducation/IndexWarrant";
import WingrabVouchers from "../pages/WarrantEducation/WingrabVouchers"
import WarrantGuestSpeakerVideo from "../pages/WarrantEducation/WarrantGuestSpeakerVideo";



import JoinTelegram from "../pages/WarrantMarket/JoinTelegram";
import DailyHighlight from "../pages/WarrantMarket/DailyHighlight";
import EndDayInsight from "../pages/WarrantMarket/EndDayInsight";
import Activitie from "../pages/WarrantMarket/Activitie";
import OvernightMarketWrap from "../pages/WarrantMarket/OvernightMarketWrap";
import Highlights from "../pages/WarrantMarket/Highlight/Highlights";
import PhotoGalleryPage from "../pages/WarrantMarket/PhotoGallery";
import PhotoDetail from "../pages/WarrantMarket/PhotoGallery/PhotoDetail";
import Subscribe from "../pages/WarrantMarket/Subscribe";
import WarrantHeros from "../pages/WarrantMarket/Activitie/Warrant2015/";
import WarrantWarriors from "../pages/WarrantMarket/Activitie/Warrant2017/";

import HkUnderlyings from "../pages/WarrantEducation/HkUnderlying"

import InvestorAcademy from "../pages/WarrantEducation/InvestorAcademyWarrant";
import WhyTradeWarrant from "../pages/WarrantEducation/InvestorAcademyWarrant/WhyTradeWarrant";
import WhatAreWarrant from "../pages/WarrantEducation/InvestorAcademyWarrant/WhatAreWarrant";
import HowSelectWarrant from "../pages/WarrantEducation/InvestorAcademyWarrant/HowSelectWarrant";
import HowWarrantsPriced from "../pages/WarrantEducation/InvestorAcademyWarrant/HowWarrantsPriced";
import IndexCallsPut from "../pages/WarrantEducation/InvestorAcademyWarrant/IndexCallsPut";
import MarketMaker from "../pages/WarrantEducation/InvestorAcademyWarrant/MarketMaker";
//import MonitoringPriceChange from '../pages/WarrantEducation/InvestorAcademyWarrant/MonitoringPriceChange';
import MonitoringPriceChanges from "../pages/WarrantEducation/InvestorAcademyWarrant/MonitoringPriceChange/MonitoringPriceChanges";
import Risk from "../pages/WarrantEducation/InvestorAcademyWarrant/Risk";
import SingleShareExpiry from "../pages/WarrantEducation/InvestorAcademyWarrant/SingleShareExpiry";
import UnderstandingWarrantStructure from "../pages/WarrantEducation/InvestorAcademyWarrant/UnderstandingWarrantStructure";
import UsefulWarrantTerm from "../pages/WarrantEducation/InvestorAcademyWarrant/UsefulWarrantTerm";
import Highlight from "../pages/Home/Highlight";
import GearingEffect from "../pages/WarrantEducation/InvestorAcademyWarrant/WhyTradeWarrant/GearingEffect";
import LimitedLoss from "../pages/WarrantEducation/InvestorAcademyWarrant/WhyTradeWarrant/LimitedLoss";
import ProfitBullBear from "../pages/WarrantEducation/InvestorAcademyWarrant/WhyTradeWarrant/ProfitBullBear";
import EaseTrade from "../pages/WarrantEducation/InvestorAcademyWarrant/WhyTradeWarrant/EaseTrade";
import LowerCapitalOutlay from "../pages/WarrantEducation/InvestorAcademyWarrant/WhyTradeWarrant/LowerCapitalOutlay";
import ReleasingShareCapital from "../pages/WarrantEducation/InvestorAcademyWarrant/WhyTradeWarrant/ReleasingShareCapital";
import NoMarginCall from "../pages/WarrantEducation/InvestorAcademyWarrant/WhyTradeWarrant/NoMarginCall";
import CallPut from "../pages/WarrantEducation/InvestorAcademyWarrant/WhatAreWarrant/CallPut";
import HowCallWarrantWork from "../pages/WarrantEducation/InvestorAcademyWarrant/WhatAreWarrant/HowCallWarrantwork";
import HowPutWarrantWork from "../pages/WarrantEducation/InvestorAcademyWarrant/WhatAreWarrant/HowPutWarrantwork";
import HowWarrantsNamed from "../pages/WarrantEducation/InvestorAcademyWarrant/WhatAreWarrant/HowWarrantsNamed";
import SharePrice from "../pages/WarrantEducation/InvestorAcademyWarrant/HowWarrantsPriced/SharePrice";
import Dividend from "../pages/WarrantEducation/InvestorAcademyWarrant/HowWarrantsPriced/Dividend";
import InterestRate from "../pages/WarrantEducation/InvestorAcademyWarrant/HowWarrantsPriced/InterestRate";
import ExercisePrice from "../pages/WarrantEducation/InvestorAcademyWarrant/HowWarrantsPriced/ExercisePrice";
import ExpiryDate from "../pages/WarrantEducation/InvestorAcademyWarrant/HowWarrantsPriced/ExpiryDate/ExpiryDate";
import Volatility from "../pages/WarrantEducation/InvestorAcademyWarrant/HowWarrantsPriced/Volatility";
import IntrinsicValueTimeValue from "../pages/WarrantEducation/InvestorAcademyWarrant/UnderstandingWarrantStructure/IntrinsicValueTimeValue";
import Moneynes from "../pages/WarrantEducation/InvestorAcademyWarrant/UnderstandingWarrantStructure/Moneynes";
import TimeDecay from "../pages/WarrantEducation/InvestorAcademyWarrant/UnderstandingWarrantStructure/TimeDecay";
import LastTradedPrice from "../pages/WarrantEducation/InvestorAcademyWarrant/MonitoringPriceChange/LastTradedPrice";
import ClosingTime from "../pages/WarrantEducation/InvestorAcademyWarrant/MonitoringPriceChange/ClosingTime/ClosingTimes";
import BidPrice from "../pages/WarrantEducation/InvestorAcademyWarrant/MonitoringPriceChange/BidPrice";
import Delta from "../pages/WarrantEducation/InvestorAcademyWarrant/UsefulWarrantTerm/Delta";
import BreakevenPrice from "../pages/WarrantEducation/InvestorAcademyWarrant/UsefulWarrantTerm/BreakevenPrice";
import ExerciseRatio from "../pages/WarrantEducation/InvestorAcademyWarrant/UsefulWarrantTerm/ExerciseRatio";
import Gearing from "../pages/WarrantEducation/InvestorAcademyWarrant/UsefulWarrantTerm/Gearing";
import EffectiveGearing from "../pages/WarrantEducation/InvestorAcademyWarrant/UsefulWarrantTerm/EffectiveGearing";
import ImpliedVolatility from "../pages/WarrantEducation/InvestorAcademyWarrant/UsefulWarrantTerm/ImpliedVolatility";
import Premium from "../pages/WarrantEducation/InvestorAcademyWarrant/UsefulWarrantTerm/Premium";
import WarrantSensitivitys from "../pages/WarrantEducation/InvestorAcademyWarrant/UsefulWarrantTerm/WarrantSensitivity";
import MakerMarkets from "../pages/WarrantEducation/InvestorAcademyWarrant/HowSelectWarrant/MakerMarkets";
import RiskMatrix from "../pages/WarrantEducation/InvestorAcademyWarrant/HowSelectWarrant/RiskMatrix";
import TipsTool from "../pages/WarrantEducation/InvestorAcademyWarrant/HowSelectWarrant/TipsTool";
import SelectingWarrant from "../pages/WarrantEducation/InvestorAcademyWarrant/HowSelectWarrant/SelectingWarrant";
import TimeFrame from "../pages/WarrantEducation/InvestorAcademyWarrant/HowSelectWarrant/SelectingWarrant/TimeFrame";
import GearingLevel from "../pages/WarrantEducation/InvestorAcademyWarrant/HowSelectWarrant/SelectingWarrant/GearingLevel";
import SelectImpliedVolatility from "../pages/WarrantEducation/InvestorAcademyWarrant/HowSelectWarrant/SelectingWarrant/SelectImpliedVolatility";
import WarrantSpread from "../pages/WarrantEducation/InvestorAcademyWarrant/HowSelectWarrant/SelectingWarrant/WarrantSpread";
import CallExample from "../pages/WarrantEducation/InvestorAcademyWarrant/SingleShareExpiry/CallExample";
import CallExpiryFormula from "../pages/WarrantEducation/InvestorAcademyWarrant/SingleShareExpiry/CallExpiryFormula";
import LastTradingDay from "../pages/WarrantEducation/InvestorAcademyWarrant/SingleShareExpiry/LastTradingDay";
import PutExample from "../pages/WarrantEducation/InvestorAcademyWarrant/SingleShareExpiry/PutExample";
import PutExpiryFormula from "../pages/WarrantEducation/InvestorAcademyWarrant/SingleShareExpiry/PutExpiryFormula";
import IndexCallExample from "../pages/WarrantEducation/InvestorAcademyWarrant/IndexCallsPut/IndexCallExample";
import IndexExpiryFormula from "../pages/WarrantEducation/InvestorAcademyWarrant/IndexCallsPut/IndexExpiryFormula";
import IndexPutExample from "../pages/WarrantEducation/InvestorAcademyWarrant/IndexCallsPut/IndexPutExample";
import MarketRisk from "../pages/WarrantEducation/InvestorAcademyWarrant/Risk/MarketRisk";
import RiskGearing from "../pages/WarrantEducation/InvestorAcademyWarrant/Risk/RiskGearing";
import TakeOver from "../pages/WarrantEducation/InvestorAcademyWarrant/Risk/TakeOver";
import LimitedLife from "../pages/WarrantEducation/InvestorAcademyWarrant/Risk/LimitedLife";
import CreditRisk from "../pages/WarrantEducation/InvestorAcademyWarrant/Risk/CreditRisk";
import HowWeHedge from "../pages/WarrantEducation/InvestorAcademyWarrant/MarketMaker/HowWeHedge";
import LiquidityVolume from "../pages/WarrantEducation/InvestorAcademyWarrant/MarketMaker/LiquidityVolume";
import SearchCom from "../pages/Search/Search";
import Chinesepage from "../components/common/Chinesepage";

// import Helmet from "react-helmet";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ImportantInformation from "../components/common/Footer/ImportantInformation";
import PrivacyPolicy from "../components/common/Footer/PrivacyPolicy";
import TermsOfUse from "../components/common/Footer/TermsOfUse";
import DisclaimerInfo from "../components/common/Footer/Disclaimer";
import { Facebook } from "react-bootstrap-icons";
import UnSubscribe from "../pages/UnSubscribe/UnSubscribe"


const UnAuthorisedApp = (props) => {
  const location = useLocation();

  function RouteWithTitle({ title,description,keyword,ogUrl,ogType,ogTitle,ogDescription,ogImage,facebookDomain, ...props }) {
    return (
      <HelmetProvider>
        <Helmet>
          <title>{title} </title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keyword}></meta>
          <meta property="og:url" content={ogUrl} />
          <meta property="og:type" content={ogType} />
          <meta property="og:title" content={ogTitle} />
          <meta property="og:description" content={ogDescription} />
          <meta property="og:image" content={ogImage} />
          <meta name="facebook-domain-verification" content={facebookDomain} />
        </Helmet>
        <Route {...props} />
      </HelmetProvider>
    );
  }
  return (
    <>
      <Header />
      <Switch>
        {/* Home & Highlight */}
        <RouteWithTitle
          exact
          title="Malaysia Warrants | Trading Stocks and Index Warrants | Macquarie"
          description="See today's top traded index and single stock structured warrants at Malaysia Warrants. Trading tools and stock market updates, all in one place."
          keyword="Malaysia warrants, structured warrants, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          path="/home"
          component={(props) => <Home {...props} />}
        />
        <RouteWithTitle
          exact
          title="Malaysia Warrants | Trading Stocks and Index Warrants | Macquarie"
          description="See today's top traded index and single stock structured warrants at Malaysia Warrants. Trading tools and stock market updates, all in one place."
          keyword="Malaysia warrants, structured warrants, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          path="/home/:id"
          component={(props) => <Home Location={location} {...props} />}
        />

        
        <RouteWithTitle
          exact
          title="Malaysia Warrants | Trading Stocks and Index Warrants | Macquarie"
          path="/home/highlight"
          component={(props) => <Highlight {...props} />}
        />
        <RouteWithTitle
          exact
          title="Malaysia Warrants | Trading Stocks and Index Warrants | Macquarie"
          path="/about/important-information"
          component={(props) => (
            <ImportantInformation Location={location} {...props} />
          )}
        />
        <RouteWithTitle
          exact
          title="Malaysia Warrants | Trading Stocks and Index Warrants | Macquarie"
          path="/about/privacy-policy"
          component={(props) => (
            <PrivacyPolicy Location={location} {...props} />
          )}
        />
        <RouteWithTitle
          exact
          title="Malaysia Warrants | Trading Stocks and Index Warrants | Macquarie"
          path="/about/terms-of-use"
          component={(props) => <TermsOfUse Location={location} {...props} />}
        />
        <RouteWithTitle
          exact
          title="Malaysia Warrants | Trading Stocks and Index Warrants | Macquarie"
          path="/about/disclaimer"
          component={(props) => (
            <DisclaimerInfo Location={location} {...props} />
          )}
        />



        {/* Warrant Tools */}
        <RouteWithTitle
          title="Live Matrix Plus | Malaysia Warrants | Macquarie"
          description="Use our live matrix to make trading warrants easy. See where our bid prices for index and single stock structured warrants will be throughout the day."
          keyword="Live matrix, live pricing, bid price"
          ogUrl={`${Base_Url}/tools/livematrix/tools/livematrix`}
          ogType="website"
          ogTitle="Live Matrix Plus"
          ogDescription="See where a warrant's bid and offer price will be"
          ogImage={`${Base_Url}/tools/livematrix/img/home/Meta%20Image%20(Op1)_V2-80.jpg`}
          facebookDomain=""
          exact
          path="/tools/livematrix/"
          component={(props) => <LiveMatrix {...props} />}
        />
        <RouteWithTitle
          title="Live Matrix Plus | Malaysia Warrants | Macquarie"
          description="Use our live matrix to make trading warrants."
          keyword="Live matrix, live pricing, bid price"
          ogUrl={`${Base_Url}/tools/livematrix/tools/livematrix`}
          ogType="website"
          ogTitle="Live Matrix Plus"
          ogDescription="See where a warrant's bid and offer price will be"
          ogImage={`${Base_Url}/tools/livematrix/img/home/Meta%20Image%20(Op1)_V2-80.jpg`}
          facebookDomain=""
          exact
          path="/tools/livematrix/:ric_id"
          component={(props) => <LiveMatrix {...props} />}
        />
        {/* <RouteWithTitle
          title="Live Matrix Plus | Malaysia Warrants | Macquarie"
          description="Use our live matrix to make trading warrants easy. See where our bid prices for index and single stock structured warrants will be throughout the day."
          keyword="Live matrix, live pricing, bid price"
          ogUrl={`${Base_Url}/tools/livematrix/tools/livematrix`}
          ogType="website"
          ogTitle="Live Matrix Plus"
          ogDescription="See where a warrant's bid and offer price will be"
          ogImage={`${Base_Url}/tools/livematrix/img/home/Meta%20Image%20(Op1)_V2-80.jpg`}
          facebookDomain=""
          exact
          path="/tools/classiclivematrix"
          component={(props) => <ClassicLiveMatrix {...props} />}
        /> */}
        <RouteWithTitle
          title="Warrant search | Malaysia Warrants | Macquarie"
          description="Use our warrant search tool to find all structured warrants that match your investment criteria to aid in your decision-making."
          keyword="warrant search, investment criteria, malaysia warrants"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/tools/warrantsearch"
          component={(props) => <WarrantSearch {...props} />}
        />
        <RouteWithTitle
          title="Warrant search | Malaysia Warrants | Macquarie"
          description="Use our warrant search tool to find all structured warrants that match your investment criteria to aid in your decision-making."
          keyword="warrant search, investment criteria, malaysia warrants"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/tools/warrantsearch/:id"
          component={(props) => <WarrantSearch {...props} />}
        />
        <RouteWithTitle
          title="Warrant terms | Malaysia Warrants | Macquarie"
          description="Find out more about your selected structured warrant by viewing all warrant terms here."
          keyword="warrant terms, Macquarie, malaysia warrants"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/tools/warrantterms"
          component={(props) => <WarrantTerms {...props} />}
        />
        <RouteWithTitle
          title="Warrant terms | Malaysia Warrants | Macquarie"
          description="Find out more about your selected structured warrant by viewing all warrant terms here."
          keyword="warrant terms, Macquarie, malaysia warrants"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/tools/warrantterms/:id"
          component={(props) => <WarrantTerms {...props} />}
        />
        <RouteWithTitle
          title="Warrant calculator | Malaysia Warrants | Macquarie"
          description="Use our warrant calculator to estimate your future structured warrant price given a change in underlying price, time to expiry or implied volatility."
          keyword="warrant calculator,  Macquarie, malaysia warrants"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/tools/warrantcalculator"
          component={(props) => <WarrantCalculator {...props} />}
        />
        <RouteWithTitle
          title="Warrant calculator | Malaysia Warrants | Macquarie"
          description="Use our warrant calculator to estimate your future structured warrant price given a change in underlying price, time to expiry or implied volatility."
          keyword="warrant calculator,  Macquarie, malaysia warrants"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/tools/warrantcalculator/:id" 
          component={(props) => <WarrantCalculator {...props} />}
        />
        <RouteWithTitle
          title="Underlying charts | Malaysia Warrants |  Macquarie"
          description="Use underlying charts to perform technical analysis on a warrant's underlying. See the underlying's traded volume and technical indicators in one click."
          keyword="underlying charts, technical analysis, traded volume"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/tools/underlying"
          component={(props) => <WarrantUnderLyingChart {...props} />}
        />
        <RouteWithTitle
          title="Underlying charts | Malaysia Warrants |  Macquarie"
          description="Use underlying charts to perform technical analysis on a warrant's underlying. See the underlying's traded volume and technical indicators in one click."
          keyword="underlying charts, technical analysis, traded volume"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/tools/underlying/:id"
          component={(props) => <WarrantUnderLyingChart {...props} />}
        />
        <RouteWithTitle
          title="Warrant Comparison | Malaysia Warrants | Macquarie"
          description="Compare multiple warrants and their key factors at one glance for faster and more optimal decision-making."
          keyword="warrant comparison, malaysia warrants, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/tools/warrantcomparison"
          component={(props) => <WarrantComparison {...props} />}
        />
        <RouteWithTitle
          title="Warrant documents | Malaysia Warrants | Macquarie"
          description="Gain access to all of Macquarie's listing documents, prospectus, and terms and conditions relevant to structured warrants issued by Macquarie."
          keyword="listing, prospectus, term sheets"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/tools/document"
          component={(props) => <WarrantDocument {...props} />}
        />
        <RouteWithTitle
          title="Warrant Heatmap | Malaysia Warrants | Macquarie"
          description="The warrant heatmap shows you whether an underlying is bullish or bearish, based on TradeVSA's proprietary algorithm. View charts, associated warrants and more."
          keyword="Warrant heatmap, TradeVSA, technical analysis, technical chart"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/tools/heatmap"
          component={(props) => <WarrantHeatmap {...props} />}
        />
        <RouteWithTitle
          title="Warrant Heatmap | Malaysia Warrants | Macquarie"
          description="The warrant heatmap shows you whether an underlying is bullish or bearish, based on TradeVSA's proprietary algorithm. View charts, associated warrants and more."
          keyword="Warrant heatmap, TradeVSA, technical analysis, technical chart"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          exact
          path="/tools/heatmap/:id"
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          component={(props) => <WarrantHeatmap {...props} />}
        />
        {/* <RouteWithTitle
          title="Technical Event Lookup | Malaysia Warrants | Macquarie"
          description="Gain access to free technical analysis on our new Technical Event Lookup. Powered by Recognia, a worldwide leader in quantitative and technical analysis."
          keyword="recognia, technical analysis, technical indicators"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/tools/recognia"
          component={(props) => <WarrantTechnicalEvent {...props} />}
        />
        <RouteWithTitle
          title="Technical Event Lookup | Malaysia Warrants | Macquarie"
          description="Gain access to free technical analysis on our new Technical Event Lookup. Powered by Recognia, a worldwide leader in quantitative and technical analysis."
          keyword="recognia, technical analysis, technical indicators"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/tools/recognia/:id"
          component={(props) => <WarrantTechnicalEvent {...props} />}
        /> */}



        {/* Warrant Statstics */}
        <RouteWithTitle
          title="Top traded stocks | Malaysia Warrants | Macquarie"
          description="View the most active under1ying shares and the corresponding structured warrant with the highest liquidity."
          keyword="top traded stocks, top traded volume, liquidity"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/marketdata/toptradedstocks"
          component={(props) => <TradedStock {...props} />}
        />
        <RouteWithTitle
          title="Top traded and top movers | Malaysia Warrants | Macquarie"
          description="View all top traded and most active index and single stock structured warrants with highest traded volume for the day."
          keyword="top traded warrants, top traded volume, active warrants"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/marketdata/toptradedwarrants"
          component={(props) => <TradedWarrant {...props} />}
        />
        <RouteWithTitle
          title="Turnover distribution | Malaysia Warrants | Macquarie"
          description="Learn more about today's warrant turnover distribution for top traded Malaysia underlyings."
          keyword="warrant turnover, turnover distribution, top traded underlyings"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/marketdata/turnoverdist"
          component={(props) => <TurnOverDistribution {...props} />}
        />
        <RouteWithTitle
          title="Money flow | Malaysia Warrants | Macquarie"
          description="See which structured warrants other investors are buying and selling. Gain access to exclusive information available weekly."
          keyword="money flow, malaysia warrants, structured warrants"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/marketdata/moneyflow"
          component={(props) => <MoneyFlow {...props} />}
        />
        <RouteWithTitle
          title="Money flow | Malaysia Warrants | Macquarie"
          description="See which structured warrants other investors are buying and selling. Gain access to exclusive information available weekly."
          keyword="money flow, malaysia warrants, structured warrants"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/marketdata/moneyflowchart"
          component={(props) => <MoneyFlowChart {...props} />}
        />
        <RouteWithTitle
          title="Warrant market turnover | Malaysia Warrants | Macquarie"
          description="Find out how active the warrants market was today compared to previous days."
          keyword="warrants market turnover, warrants turnover, malaysia warrants"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/marketdata/turnover"
          component={(props) => <MarketTurnOver {...props} />}
        />
        <RouteWithTitle
          title="Index futures rollover | Malaysia Warrants | Macquarie"
          description="Find out how active the warrants market was today compared to previous days."
          keyword="warrants market turnover, warrants turnover, malaysia warrants"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/education/Indexfuturesrollover"
          component={(props) => <IndexFuturesRollover {...props} />}
        />



        {/* Warrant Education */}
        <RouteWithTitle
          title="Seminars | Malaysia Warrants | Macquarie"
          description="Register online on our website to attend warrant seminars for FREE!"
          keyword="call warrants, seminar, malaysia warrants"
          ogUrl=""
          ogType="website"
          ogTitle="Macquarie"
          ogDescription=""
          ogImage={`${Base_Url}/photo?seminar=&lang=en`}
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/education/seminar"
          component={(props) => <Seminar {...props} />}
        />
        <RouteWithTitle
          title="Gain exposure to HK market | Malaysia Warrants| Macquarie"
          description="Learn about the Hang Seng Index, Hang Seng TECH Index and Hong Kong shares, and how to gain leveraged exposure to them with structured warrants listed on Bursa!"
          keyword="Malaysia Warrants, Hong Kong Underlyings, Macquarie"
          ogUrl={`${Base_Url}/warrant-education/HKunderlyings`}
          ogType="website"
          ogTitle="Gain exposure to HK market"
          ogDescription="Learn more about warrants over HSI, HSTECH and HK shares"
          ogImage={`${Base_Url}/img/hkunderlyings/fb_meta_image.png`}
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/warrant-education/HKunderlyings"
          component={(props) => <GainExposure {...props} />}
        />
        <RouteWithTitle
          title="FAQ | Malaysia Warrants | Macquarie"
          description="Not familiar with warrants? Browse through the frequently asked questions and have all of your questions regarding warrants answered."
          keyword="call warrants, structured warrants, warrants FAQ"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/education/faq"
          component={(props) => <FrequentlyAskedQues {...props} />}
        />
        <RouteWithTitle
          title="How to get started | Malaysia Warrants | Macquarie"
          description="Want to get started with trading warrants but not sure how? See our step by step guide now!"
          keyword="how to get started, where to trade warrants, Malaysia warrants"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/education/getstarted"
          component={(props) => <HowToStart {...props} />}
        />
        <RouteWithTitle
          title="Structured warrants: take your first step now"
          description="Welcome to Macquarie Warrants. Take your first step towards learning about structured warrants now!"
          keyword="Malaysia warrants, structured warrants, Macquarie"
          ogUrl={`${Base_Url}/FirstStep`}
          ogType="website"
          ogTitle="Structured Warrants: First Step"
          ogDescription="Take your first step towards learning about structured warrants now!"
          ogImage={`${Base_Url}/img/landing/ogMetaImg.jpg`}
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/FirstStep"
          component={(props) => <YourFirstStep {...props} />}
        />
        <RouteWithTitle
          title="Malaysia Warrants | Success Speaks | Macquarie"
          description="What does it take to be a warrant trader? Introducing Joey, Jason and TK, who have generously agreed to be interviewed and to share their secrets with you!"
          keyword="Malaysia Warrants, Success Speaks, Macquarie"
          ogUrl={`${Base_Url}/SuccessSpeaks`}
          ogType="website"
          ogTitle="Success Speaks"
          ogDescription="What does it take to be a warrant trader?"
          ogImage={`${Base_Url}/img/education/fbogmeta.png`}
          facebookDomain=""
          exact
          path="/SuccessSpeaks"
          component={(props) => <SuccessSpeak {...props} />}
        />
        <RouteWithTitle
          title="Warrant videos | Malaysia Warrants | Macquarie"
          description="Watch our animated series and our guest speaker videos to improve your knowledge in structured warrants now!"
          keyword="warrant video, structured warrants, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/education/warrantvideos"
          component={(props) => <WarrantGuestSpeakerVideo {...props} />}
        />
        <RouteWithTitle
          title="Why Macquarie? | Malaysia Warrants | Macquarie"
          description="Macquarie is the leading warrants issuer in Asia with the largest market share in Singapore and Malaysia. Learn more about our business strengths here."
          keyword="structured warrants, malaysia warrants, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/education/whymacquarie"
          component={(props) => <WhyMacquarie {...props} />}
        />
        <RouteWithTitle
          title="Warrant videos"
          description=""
          keyword=""
          ogUrl=""
          ogType="website"
          ogTitle="Episode 5: How to use the Live Matrix for real time warrant prices  (3:17)"
          ogDescription="Macquarie’s Live Matrix shows you where Macquarie warrant’s bid and offer prices will be, in real time!"
          ogImage="https://i.ytimg.com/vi/y7Zkk7yuoDs/hqdefault.jpg"
          facebookDomain=""
          exact
          path="/education/warrantvideosind"
          component={(props) => <HowUseLiveMatrix {...props} />}
        />
        <RouteWithTitle
          title="Warrant videos"
          description=""
          keyword=""
          ogUrl=""
          ogType="website"
          ogTitle="Episode 5: How to use the Live Matrix for real time warrant prices  (3:17)"
          ogDescription="Macquarie’s Live Matrix shows you where Macquarie warrant’s bid and offer prices will be, in real time!"
          ogImage="https://i.ytimg.com/vi/y7Zkk7yuoDs/hqdefault.jpg"
          facebookDomain=""
          exact
          path="/education/warrantvideosind/:index/:id"
          component={(props) => <HowUseLiveMatrix {...props} />}
        />
        <RouteWithTitle
          title="Index warrants | Malaysia Warrants | Macquarie"
          description="Learn more about Index Warrants issued by Macquarie, the first to issue warrants over the Hang Seng TECH Index on Bursa Malaysia."
          keyword="index warrant, HSI warrant, HSTECH warrant"
          ogUrl={`${Base_Url}/education/indexwarrants`}
          ogType="website"
          ogTitle="Trade index warrants "
          ogDescription="Check out the warrants over Hang Seng TECH Index and other index underlyings!"
          ogImage={`${Base_Url}/img/home/HSTECH_OG_Meta.png`}
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/education/indexwarrants/:underlying"
          component={(props) => <IndexWarrant {...props} />}
        />
        <RouteWithTitle
          title="Index warrants | Malaysia Warrants | Macquarie"
          description="Learn more about Index Warrants issued by Macquarie, the first to issue warrants over the Hang Seng TECH Index on Bursa Malaysia."
          keyword="index warrant, HSI warrant, HSTECH warrant"
          ogUrl={`${Base_Url}/education/indexwarrants`}
          ogType="website"
          ogTitle="Trade index warrants "
          ogDescription="Check out the warrants over Hang Seng TECH Index and other index underlyings!"
          ogImage={`${Base_Url}/img/home/HSTECH_OG_Meta.png`}
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/education/indexwarrants"
          component={(props) => <IndexWarrant {...props} />}
        />
        <RouteWithTitle
          title=""
          description=""
          keyword=""
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          exact
          path="/education/wingrabvouchers"
          component={(props) => <WingrabVouchers {...props} />}
        />
        {/* <RouteWithTitle
          title="Warrant videos"
          description=""
          keyword=""
          ogUrl=""
          ogType="website"
          ogTitle="Episode 5: How to use the Live Matrix for real time warrant prices  (3:17)"
          ogDescription="Macquarie’s Live Matrix shows you where Macquarie warrant’s bid and offer prices will be, in real time!"
          ogImage="https://i.ytimg.com/vi/y7Zkk7yuoDs/hqdefault.jpg"
          facebookDomain=""
          exact
          path="/education/warrantvideosind"
          component={(props) => <WarrantGuestSpeakerVideo {...props} />}
        />
         <RouteWithTitle
          title="Warrant videos"
          description=""
          keyword=""
          ogUrl=""
          ogType="website"
          ogTitle="Episode 5: How to use the Live Matrix for real time warrant prices  (3:17)"
          ogDescription="Macquarie’s Live Matrix shows you where Macquarie warrant’s bid and offer prices will be, in real time!"
          ogImage="https://i.ytimg.com/vi/y7Zkk7yuoDs/hqdefault.jpg"
          facebookDomain=""
          exact
          path="/education/warrantvideosind/:id"
          component={(props) => <WarrantGuestSpeakerVideo {...props} />}
        /> */}

        <RouteWithTitle
          title="Gain exposure to HK market | Malaysia Warrants| Macquarie"
          description="Learn about the Hang Seng Index, Hang Seng TECH Index and Hong Kong shares, and how to gain leveraged exposure to them with structured warrants listed on Bursa!"
          keyword="Malaysia Warrants, Hong Kong Underlyings, Macquarie"
          ogUrl={`${Base_Url}/warrant-education/HKunderlyings`}
          ogType="website"
          ogTitle="Gain exposure to HK market"
          ogDescription="Learn more about warrants over HSI, HSTECH and HK shares"
          ogImage={`${Base_Url}/img/hkunderlyings/fb_meta_image.png`}
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/HKunderlyings"
          component={(props) => <HkUnderlyings {...props} />}
        />


        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu"
          component={(props) => <InvestorAcademy {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/why-trade-warrant"
          component={(props) => <WhyTradeWarrant {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/what-are-warrant"
          component={(props) => <WhatAreWarrant {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/select-warrant"
          component={(props) => <HowSelectWarrant {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/how-are-warrant-priced"
          component={(props) => <HowWarrantsPriced {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/index-calls"
          component={(props) => <IndexCallsPut {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/market-marker"
          component={(props) => <MarketMaker {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/monitoring-price-changes"
          component={(props) => <MonitoringPriceChanges {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/risks"
          component={(props) => <Risk {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/single-share-calls"
          component={(props) => <SingleShareExpiry {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/understanding-warrant-structure"
          component={(props) => <UnderstandingWarrantStructure {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/useful-warrant-terms"
          component={(props) => <UsefulWarrantTerm {...props} />}
        />

        {/* Warrant Market */}
        <RouteWithTitle
          title="Join us on Telegram!"
          description=""
          keyword=""
          ogUrl={`${Base_Url}/telegram`}
          ogType="website"
          ogTitle="Join the Malaysia Warrants Telegram channel"
          ogDescription="Get timely updates on structured warrants as well as the foreign and local markets!"
          ogImage={`${Base_Url}/img/telegram/telegram.png`}
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/telegram"
          component={(props) => <JoinTelegram {...props} />}
        />
        <RouteWithTitle
          title="Daily highlights | Malaysia Warrants | Macquarie"
          description="Keep up to date with daily highlights on the stock market and other relevant news from Macquarie research and more."
          keyword="daily market highlights, stock market, research"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/marketnews/dailyhighlights"
          component={(props) => <DailyHighlight {...props} />}
        />
        <RouteWithTitle
          title="End of day insights | Malaysia Warrants | Macquarie"
          description="Gain access to Macquarie's exclusive commentary on the stock market and other relevant news, updated by 6pm each day."
          keyword="market insights, stock market, research"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/marketnews/eodinsights"
          component={(props) => <EndDayInsight {...props} />}
        />
        <RouteWithTitle
          title="Overnight market wrap | Malaysia Warrants | Macquarie"
          description="Stay on top of the latest news in the global markets to ensure that you make the most informed decisions on any trading day."
          keyword="S&P 500 Index, US stocks, global markets"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/marketnews/overnightmarkets"
          component={(props) => <OvernightMarketWrap {...props} />}
        />
        <RouteWithTitle
          title="Malaysia Warrants | Macquarie"
          description=""
          keyword=""
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/marketnews/highlight"
          component={(props) => <Highlights {...props} />}
        />
        {/* <RouteWithTitle
          title="Activities | Malaysia Warrants | Macquarie"
          description="Check out the latest events and activities organised by Macquarie."
          keyword="Macquarie, events, malaysia warrants"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/marketnews/activities"
          component={(props) => <Activitie {...props} />}
        /> */}
        {/* <RouteWithTitle
          title="Photo gallery | Malaysia Warrants | Macquarie"
          description="Visit our photo gallery to get a closer look at all our recent events."
          keyword="Photos, Macquarie, malaysia warrants"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/marketnews/photogallery"
          component={(props) => <PhotoGalleryPage {...props} />}
        /> */}
        <RouteWithTitle
          title="Photo gallery | Malaysia Warrants | Macquarie"
          description="Visit our photo gallery to get a closer look at all our recent events."
          keyword="Photos, Macquarie, malaysia warrants"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/marketnews/individualgallery"
          component={(props) => <PhotoDetail {...props} />}
        />
        <RouteWithTitle
          title="Subscribe |  Malaysia Warrants | Macquarie"
          description="Subscribe to Macquarie's daily newsletter. Start each day with the inside scoop on what's happening in the warrants market."
          keyword="newsletter, malaysia warrants, subscribe"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain="gysvbbpef4ffs8np35mfk6djt8dofq"
          exact
          path="/marketnews/subscribe"
          component={(props) => <Subscribe {...props} />}
        />
        <RouteWithTitle
          title="Warrant Hero 2015"
          description=""
          keyword=""
          ogUrl=""
          ogType="website"
          ogTitle="Warrant Hero 2015"
          ogDescription="Warrant Hero 2015"
          ogImage={`${Base_Url}/img/activities/warrant_hero_2015.jpg`}
          facebookDomain=""
          exact
          path="/marketnews/activities/hero/2015"
          component={(props) => <WarrantHeros {...props} />}
        />
        <RouteWithTitle
          title="Warrant Warrior 2017"
          description=""
          keyword=""
          ogUrl=""
          ogType="website"
          ogTitle="Warrant Warrior 2017"
          ogDescription="Warrant Warrior 2017"
          ogImage={`${Base_Url}/img/activities/warrant_warrior_2017.jpg`}
          facebookDomain=""
          exact
          path="/marketnews/activities/warrior/2017"
          component={(props) => <WarrantWarriors {...props} />}
        />

        {/*///////////////// Investor Academy All Pages ////////////////////*/}

        {/* Why Trade Warrant ALL Pages */}
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/why-trade-warrant/gearing-effect"
          component={(props) => <GearingEffect {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/why-trade-warrant/limited-loss"
          component={(props) => <LimitedLoss {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/why-trade-warrant/profit-bull-bear"
          component={(props) => <ProfitBullBear {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/why-trade-warrant/ease-trade"
          component={(props) => <EaseTrade {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/why-trade-warrant/lower-capital-outlay"
          component={(props) => <LowerCapitalOutlay {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/why-trade-warrant/no-margin-call"
          component={(props) => <NoMarginCall {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/why-trade-warrant/releasing-share-capital"
          component={(props) => <ReleasingShareCapital {...props} />}
        />

        {/* What Are Warrant ALL Pages */}
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/what-are-warrant/call-vs-put"
          component={(props) => <CallPut {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/what-are-warrant/call-warrant-work"
          component={(props) => <HowCallWarrantWork {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogImage=""
          facebookDomain=""
          ogDescription=""
          exact
          path="/edu/what-are-warrant/put-warrant-work"
          component={(props) => <HowPutWarrantWork {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/what-are-warrant/warrant-named"
          component={(props) => <HowWarrantsNamed {...props} />}
        />

        {/* How are Warrants priced ALL Pages */}
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/how-are-warrant-priced/share-price"
          component={(props) => <SharePrice {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/how-are-warrant-priced/exercise"
          component={(props) => <ExercisePrice {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/how-are-warrant-priced/expiry-date"
          component={(props) => <ExpiryDate {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/how-are-warrant-priced/volatility"
          component={(props) => <Volatility {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/how-are-warrant-priced/dividend"
          component={(props) => <Dividend {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/how-are-warrant-priced/interest-rates"
          component={(props) => <InterestRate {...props} />}
        />

        {/* Understanding Warrant Structure ALL Pages */}
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/understanding-warrant-structure/time-decay"
          component={(props) => <TimeDecay {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/understanding-warrant-structure/intrinsic-value-time-value"
          component={(props) => <IntrinsicValueTimeValue {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/understanding-warrant-structure/moneyness"
          component={(props) => <Moneynes {...props} />}
        />

        {/* Monitoring price changes ALL Pages */}
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/monitoring-price-changes/bid-price"
          component={(props) => <BidPrice {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/monitoring-price-changes/last-traded-price"
          component={(props) => <LastTradedPrice {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/monitoring-price-changes/closing-times"
          component={(props) => <ClosingTime {...props} />}
        />

        {/* Useful Warrant terms ALL Pages */}
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/useful-warrant-terms/premium"
          component={(props) => <Premium {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/useful-warrant-terms/warrant-sensitivity"
          component={(props) => <WarrantSensitivitys {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/useful-warrant-terms/break-even-price"
          component={(props) => <BreakevenPrice {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/useful-warrant-terms/delta"
          component={(props) => <Delta {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/useful-warrant-terms/excercise-ratio"
          component={(props) => <ExerciseRatio {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/useful-warrant-terms/implied-volatility"
          component={(props) => <ImpliedVolatility {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/useful-warrant-terms/gearing"
          component={(props) => <Gearing {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/useful-warrant-terms/effective-gearing"
          component={(props) => <EffectiveGearing {...props} />}
        />

        {/* How to select a Warrant ALL Pages */}
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/select-warrant/selecting-warrant"
          component={(props) => <SelectingWarrant {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/select-warrant/selecting-warrant/time-frame"
          component={(props) => <TimeFrame {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/select-warrant/selecting-warrant/gearing-level"
          component={(props) => <GearingLevel {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/select-warrant/selecting-warrant/select-implied-volatility"
          component={(props) => <SelectImpliedVolatility {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/select-warrant/selecting-warrant/warrant-spread"
          component={(props) => <WarrantSpread {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/select-warrant/market-maker"
          component={(props) => <MakerMarkets {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/select-warrant/tips-and-tool"
          component={(props) => <TipsTool {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/select-warrant/risk-matrix"
          component={(props) => <RiskMatrix {...props} />}
        />

        {/* Single share calls and puts at expiry ALL Pages */}
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/single-share-calls/last-trading-day"
          component={(props) => <LastTradingDay {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/single-share-calls/call-example"
          component={(props) => <CallExample {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/single-share-calls/put-expiry-formula"
          component={(props) => <PutExpiryFormula {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/single-share-calls/put-example"
          component={(props) => <PutExample {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/single-share-calls/call-expiry-formula"
          component={(props) => <CallExpiryFormula {...props} />}
        />

        {/* Index calls and puts at expiry ALL Pages */}
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/index-calls/put-example"
          component={(props) => <IndexPutExample {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/index-calls/call-example"
          component={(props) => <IndexCallExample {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/index-calls/index-expiry"
          component={(props) => <IndexExpiryFormula {...props} />}
        />

        {/* Risk ALL Pages */}
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/risks/market-risk"
          component={(props) => <MarketRisk {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/risks/gearing"
          component={(props) => <RiskGearing {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/risks/limited-life"
          component={(props) => <LimitedLife {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/risks/credit-risk"
          component={(props) => <CreditRisk {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/risks/takeovers"
          component={(props) => <TakeOver {...props} />}
        />

        {/* Market Marker ALL Pages */}
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/market-marker/liquidity-vs-volume"
          component={(props) => <LiquidityVolume {...props} />}
        />
        <RouteWithTitle
          title="Investor academy | Malaysia Warrants | Macquarie"
          description="Empower yourself with knowledge in structured warrants through Macquarie's Investor Academy. "
          keyword="warrant education, call warrant, Macquarie"
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/edu/market-marker/how-we-hedge"
          component={(props) => <HowWeHedge {...props} />}
        />

        <RouteWithTitle
          title="Page search"
          description=""
          keyword=""
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/search"
          component={(props) => <SearchCom {...props} />}
        />
        

        <RouteWithTitle
          title=""
          description=""
          keyword=""
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/unsubscribe"
          component={(props) => <UnSubscribe {...props} />}
        />

        <RouteWithTitle
          title=""
          description=""
          keyword=""
          ogUrl=""
          ogType=""
          ogTitle=""
          ogDescription=""
          ogImage=""
          facebookDomain=""
          exact
          path="/education/glossaryCN"
          component={(props) => <Chinesepage {...props} />}
        />

        <Redirect
          to={{
            pathname: "/home",
            state: { from: location },
          }}
        />
      </Switch>
      <Footer />

      <div id="xsTest" className="visible-xs"></div>
      <div id="smTest" className="visible-sm"></div>
      <div id="mdTest" className="visible-md"></div>
      <div id="lgTest" className="visible-lg"></div>

      <Disclaimer />
      <Hotline />
      <ShareBtn Location={location} />
    </>
  );
};

export default UnAuthorisedApp;
