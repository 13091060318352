import React, { useState, useEffect,Fragment } from 'react';
import WarrantMarketTurnOver from './IndexFuturesRollover';
import "./IndexFuturesRollover.css"

const IndexFuturesRollover = (props) => {
    return ( 
        <Fragment>
        <WarrantMarketTurnOver/>
        </Fragment>
     );
}
 

export default IndexFuturesRollover;