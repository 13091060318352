import React, { useState, useEffect, Children } from "react";
import "./warrantSearch.css";
import API from "../../../utils/apiCalling";
import {Link, useHistory, useParams } from "react-router-dom";
import { get } from "lodash";
import Select from "react-select";
import { config } from "../../../utils/apiUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { faSortUp } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import JoinTelegramModal from "../../../components/common/joinTelegramModal";
import ReactTooltip from "react-tooltip";
import { MultiSelect } from "react-multi-select-component";
import LoaderSpin from "../../../components/common/LoaderSpin";
import OutsideClickHandler from "react-outside-click-handler";
import GaEventsTracker from "../../../components/common/GaEventsTracker";

const WarrantSearch = () => {
  const useGaEventsTracker = GaEventsTracker("External Links");
  const history = useHistory()
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const api = new API();

  const [boxVisibility, setBoxVisibility] = useState("hide");
  const showNewBox = () => {
    if (boxVisibility === "hide") {
      setBoxVisibility("show");
    } else if (boxVisibility === "show") {
      setBoxVisibility("hide");
    } else {
      setBoxVisibility("hide");
    }
  };

  const queryUnderlying = new URLSearchParams(useLocation().search).get(
    "underlying"
  );
  const queryType = new URLSearchParams(useLocation().search).get("type");
  const queryExpiry = new URLSearchParams(useLocation().search).get("expiry");

  const [headerColor, setHeaderColor] = useState("none");

  const impliedVolalitiyData = (warrantData) => {
    var iv = warrantData.impliedVolalitiy;
    if (
      iv == "0.0" ||
      warrantData.delta.toString().indexOf("<") == 0 ||
      warrantData.delta.toString().indexOf(">") == 0
    ) {
      iv = "N/A";
    }
    return iv;
  };

  const [expiryDates, setExpiryDates] = useState([]);
  const [underlying, setUnderlying] = useState([]);
  const [MACQ, setMACQ] = useState([]);

  const [warrantSearchData, setWarrantSearchData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [initialised, setinitialised] = useState(false);

  const [undefinedSelected, setUndefinedSelected] = useState(null);
  const [issuerSelected, setIssuerSelected] = useState([
    { label: "MACQ", value: "Macquarie" },
  ]);
  const [maturitySelected, setMaturitySelected] = useState({
    value: "all",
    label: "All maturity",
  });
  const [moneynessPercentSelected, setMoneynessPercentSelected] =
    useState("all");
  const [moneynessSelected, setMoneynessSelected] = useState({
    value: "all",
    label: "All moneyness",
  });
  const [underlyingSelected, setUnderlyingSelected] = useState({
    value: "all",
    label: "All underlying",
  });
  const [effectiveGearingSelected, setEffectiveGearingSelected] = useState({
    value: "all",
    label: "All Eff. gearing",
  });
  const [sortBySelected, setSortBySelected] = useState("");
  const [indicatorSelected, setIndicatorSelected] = useState({
    value: "all",
    label: "All indicators",
  });
  const [sortOrderSelected, setSortOrderSelected] = useState("asc");
  const [expirySelected, setExpirySelected] = useState({
    value: "all",
    label: "All expiry",
  });
  const [qidSelected, setQidSelected] = useState(Date.now());

  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");

  const callPut = [
    { value: "all", label: "Call/Put" },
    { value: "call", label: "Call" },
    { value: "put", label: "Put" },
  ];
  const [typeSelected, setTypeSelected] = useState(
    queryType && queryType !== ""
      ? callPut.filter((el) => {
          return el.value === queryType;
        })[0]
      : { value: "all", label: "Call/Put" }
  );
  const moneyness = [
    { value: "all", label: "All moneyness" },
    { value: "atm", label: "At the money" },
    { value: "itm", label: "In the money" },
    { value: "otm", label: "Out of the money" },
  ];

  const maturity = [
    { value: "all", label: "All maturity" },
    { value: "lt1", label: "< 1 month" },
    { value: "btw1t3", label: "1-3 months" },
    { value: "btw3t6", label: "3-6 months" },
    { value: "btw6t9", label: "6-9 months" },
    { value: "mt9", label: "> 9 months" },
  ];

  const effectiveGearing = [
    { value: "all", label: "All Eff. gearing" },
    { value: "ls5", label: "< 5x" },
    { value: "btw5t10", label: "5-10x" },
    { value: "btw10t15", label: "10-15x" },
    { value: "mt15", label: "> 15x" },
  ];

  const indicators = [
    { value: "all", label: "All indicators" },
    { value: "HOTLIST", label: "Trending warrants" },
    { value: "HIGHLQDY", label: "Highest liquidity" },
    { value: "APPCHEXPRY", label: "Near expiry" },
    { value: "SOLDOUT", label: "Inventory sold-out" },
    { value: "NEWWARRANT", label: "New warrant" },
    { value: "MORERESPONSIVE", label: "More responsive" },
    { value: "HIGHEFFECTIVEGEARING", label: "High effective gearing" },
    { value: "LOWTIMEDECAY", label: "Low time decay" },
  ];

  const convertIdUnderlying = () => {
    if (id === "HKIndices") {
      return "HSI,HSTECH,A50"
    } else if (id === "USIndices") {
      return "SPX,DJIA,NDX,S&P500"
    } else if (id === "ETF") {
      return "2823,2840"
    } else {
      return id
    }
  }

  const listenScrollEvent = () => {
    window.scrollY > 500 ? setHeaderColor("block") : setHeaderColor("none");
  };

  useEffect(() => {
    getWarrantSearch();
  }, []);
  useEffect(() => {
    if (initialised) {
      getWarrantSearchTable(sortOrderSelected, sortBySelected);
    }
    return () => {};
  }, [initialised]);

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  });
  const getWarrantSearch = async () => {
    let WarrantSearchResult = await api.get(config.homeWarrantSearch, true, {
      init: 1,
      qid: Date.now(),
    });
    let expiryDatesRes = get(WarrantSearchResult, "expiryDates", []);
    let underlyingRes = get(WarrantSearchResult, "underlyings", []);
    let issuersRes = get(WarrantSearchResult, "issuers", []);
    var tempUnderLying = underlyingRes.map(
      ({ underlying_name, underlying_ticker,underlying_ric }) => 
        {
          // if(underlying_ticker=='KLSE'){
          //   return {
          //     label: `${underlying_name} ${"("}${'FBMKLCI'}${")"}`,
          //     value: underlying_ticker,
          //   }
          // }else if(underlying_ticker=='S&P500'){
          //   return {
          //     label: `${underlying_name} ${"("}${'SP500'}${")"}`,
          //     value: underlying_ticker,
          //   }
          // }else if(underlying_ticker=='N225'){
          //   return {
          //     label: `Nikkei Stock Average (Nikkei 225) Index`,
          //     value: underlying_ticker,
          //   }
          // }else{
            // if(underlying_ric=='9988.HK'){
            //   console.log(underlying_ric,getUnderlyingTickerDisplayName(underlying_ric,underlying_name,1))
            // }
            return {
              label: `${getUnderlyingTickerDisplayName(underlying_ric,underlying_name,1)} ${"("}${getUnderlyingTickerDisplayName(underlying_ric,underlying_ticker,2)}${")"}`,
              value: underlying_ticker,
            }
        // }
        }
        
    );
    tempUnderLying = tempUnderLying.filter((item)=>{
      return  item.value!='N225'
    })
    tempUnderLying.unshift(
      { value: "all", label: "All underlying" },
      { value: "Indices", label: "Indices" },
      { value: "HSI,HSTECH,A50", label: "HK indices" },
      { value: "SPX,DJIA,NDX,S&P500", label: "US indices" },
      { value: "N225", label: "Nikkei Stock Average (Nikkei 225) Index" },
      { value: "mystocks", label: "Malaysia stocks" },
      { value: "hkstocks", label: "Hong Kong stocks" },
      { value: "2823,2840", label: "ETF" },
    );
    const tempExpiryDates = expiryDatesRes.map(
      ({ num: label, num: value }) => ({ label, value })
    );
    tempExpiryDates.unshift({ value: "all", label: "All expiry" });
    const tempIssues = issuersRes.map(({ issuer: label, issuer: value }) => ({
      label,
      value,
    }));
    tempIssues.splice(4, 1);
    tempIssues.unshift({ label: "MACQ", value: "Macquarie" });
    let selectedValue = queryUnderlying && queryUnderlying !== "" ? queryUnderlying: id && id !== "" ? convertIdUnderlying(id) : ""
    var sund =
        selectedValue && selectedValue !== ""
        ? tempUnderLying.filter((el) => {
            return el.value.toLowerCase() === selectedValue.toLowerCase();
          })
        : { value: "all", label: "All underlying" };
    const sexp = tempExpiryDates.filter((el) => {
      return el.value === queryExpiry;
    })[0];
    setExpiryDates(tempExpiryDates);
    setUnderlying(tempUnderLying);
    setExpirySelected(sexp);
    setUnderlyingSelected(sund);
    setMACQ(tempIssues);
    setinitialised(true);
  };
  const getUnderlyingTickerDisplayName = (val,name,type)=>{
    let UnderlyingtickerdisplaynameMap = JSON.parse(window.localStorage.getItem('UnderlyingtickerdisplaynameMap'))
    let value = UnderlyingtickerdisplaynameMap.filter((v)=>{
      return v.underlying_ric == val
    })
    if(value.length>0){
      if(type==1){
        return value[0].underlying_name
      }else{
        return value[0].underlying_ticker
      }
    }else{
      return name
    }
  }
  const getWarrantSearchTable = async (sortOrder, sortBy, selectQuery) => {
    setIsLoading(true);

    let underlying = underlyingSelected[0] !== undefined && underlyingSelected[0].value === "all" ? "all" : underlyingSelected[0] ? underlyingSelected[0].value : underlyingSelected.value
    if (selectQuery) {
      history.push(`/tools/warrantsearch/${underlying}`)
    }

    let data = {
      underlying: underlying,
      type: typeSelected === null ? "all" : typeSelected.value,
      issuer:
        issuerSelected.length === MACQ.length
          ? "all"
          : issuerSelected.map(({ value }) => value).join(";"),
      maturity: maturitySelected.value,
      moneyness: moneynessSelected.value,
      // moneynessPercent: moneynessPercentSelected === null ? "all" : moneynessPercentSelected.value,
      moneynessPercent: "all",
      effectiveGearing: effectiveGearingSelected.value,
      expiry:
        expirySelected === null || expirySelected === undefined
          ? "all"
          : expirySelected.value,
      indicator: indicatorSelected.value,
      sortBy,
      qid: qidSelected,
      sortOrder,
    };
    let WarrantSearchTableResult = await api.get(
      config.homeWarrantSearch,
      true,
      data
    );
    if (WarrantSearchTableResult && WarrantSearchTableResult.data) {
      let warrantSearchDataRes = get(WarrantSearchTableResult, "data", []);
      setWarrantSearchData(warrantSearchDataRes);
      setIsLoading(false);
    }
  };

  const sortTable = (type, sortBy) => {
    getWarrantSearchTable(type, sortBy);
    setSortOrderSelected(type);
    setSortBySelected(sortBy);
  };

  const onClickPopHandle = (url, value) => {
    setOpen(value);
    setUrl(url);
  };

  const setClass = (val) => {
    let className = " col-priceChange val bordered ";
    if (val?.toString().includes("+")) {
      className += "up";
    } else if (val?.toString().includes("-")) {
      className += "down";
    }
    return className;
  };

  const colourStyles = {
    option: (provided, state) => {
      return {
        backgroundColor: state.isFocused
          ? "#f5f5f5"
          : ["1", "2"].includes(state.innerProps.id.split("-")[4])
          ? "#fff"
          : null,
        color: "#333",
        fontSize: "14px",
        padding: "3px 20px",
        cursor: "pointer",
      };
    },
  };
  const colourStyles1 = {
    option: (style, { isSelected, isFocused }) => {
      return {
        ...style,
        backgroundColor: isFocused ? "#ffffff" : null,
        color: "blue",
        fontSize: "20px",
        padding: "3px 20px",
        cursor: "pointer",
      };
    },
  };
  const colourStyles2 = {
    option: (provided, state) => {
      return {
        backgroundColor: state.isFocused
          ? "#007fff"
          : ["1", "2","3","4",'5','6','7'].includes(state.innerProps.id.split("-")[4])
          ? "#FECC0A"
          : null,
        color: state.isFocused ? "#ffffff" : null,
        fontSize: "14px",
        padding: "3px 14px 3px 5.6px",
        cursor: "pointer",
        borderTop: state.isFocused ? "1px solid #003eff" : null,
        borderBottom: state.isFocused ? "1px solid #003eff" : null,
      };
    },
  };

  function AffinHwang() {
    useGaEventsTracker("event", "Click", { event_category: "AHIB" });
    onClickPopHandle("https://einvest.affinhwang.com/", true);
  }

  function HongLeong() {
    useGaEventsTracker("event", "Click", { event_category: "HLIB" });
    onClickPopHandle("https://www.hlebroking.com/v3/", true);
  }

  function MalaccaSecurities() {
    useGaEventsTracker("event", "Click", { event_category: "MalaccaSec" });
    onClickPopHandle("https://www.mplusonline.com.my/macsecos/index.asp", true);
  }

  function PublicBank() {
    useGaEventsTracker("event", "Click", { event_category: "PublicBank" });
    onClickPopHandle(
      "https://www.pbebank.com/Personal-Banking/Invest/Share-Trading-Financing/Application-for-PB-Sharelink.aspx",
      true
    );
  }

  function UobKayHian() {
    useGaEventsTracker("event", "Click", { event_category: "UOB" });
    onClickPopHandle("https://www.utrade.com.my/", true);
  }

  return (
    <>
      <div id="dwsearch" className="pageobj">
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <Link to="/home">Warrant tools</Link>
              </li>
              <li className="active">Warrant search</li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg" />
        <div className="container-fluid">
          <div className="section">
            <div className="page-header">
              <h1>Warrant search</h1>
              <div>
                <input
                  className="btn btn-primary tradeEl tradeBtn"
                  defaultValue="Trade warrants here"
                  type="button"
                  data-bs-toggle="collapse"
                  href="#tradeEl5"
                  role="button"
                  aria-expanded="false"
                  aria-controls="tradeEl5"
                  id="tradeButton31"
                />
                <OutsideClickHandler
                  onOutsideClick={() => {
                    document
                      .getElementById("tradeEl5")
                      .classList.remove("show");
                    document
                      .getElementById("tradeButton31")
                      .setAttribute("aria-expanded", "false");
                  }}
                >
                  <div className="tradeDropDown tradeEl collapse" id="tradeEl5">
                    <Link
                      className="btn btn-primary tradeEl"
                      to="/education/getstarted"
                      type="button"
                      target="_blank"
                      onClick={useGaEventsTracker("event", "Click", {
                        event_category: "GetStarted",
                      })}
                    >
                      How to get started
                    </Link>
                    <div className="dropdown-or tradeEl">
                      <hr className="tradeEl" />
                      <p className="tradeEl">or</p>
                      <hr className="tradeEl" />
                    </div>
                    <p className="tradeEl">Select your broker:</p>
                    <div className="row tradeEl">
                      <input
                        className="btn btn-primary broker_link tradeEl"
                        defaultValue="Affin Hwang"
                        type="button"
                        onClick={AffinHwang}
                      />
                      <input
                        className="btn btn-primary broker_link tradeEl"
                        link="https://www.hlebroking.com/v3/"
                        defaultValue="Hong Leong"
                        type="button"
                        onClick={HongLeong}
                      />
                    </div>
                    <div className="row tradeEl">
                      <input
                        className="btn btn-primary broker_link tradeEl"
                        link="https://www.mplusonline.com.my/macsecos/"
                        defaultValue="Malacca Securities"
                        type="button"
                        onClick={MalaccaSecurities}
                      />
                      <input
                        className="btn btn-primary broker_link tradeEl"
                        link="https://www.pbebank.com/Personal-Banking/Invest/Share-Trading-Financing/Application-for-PB-Sharelink.aspx"
                        defaultValue="Public Bank"
                        type="button"
                        onClick={PublicBank}
                      />
                    </div>
                    <div className="row tradeEl">
                      <input
                        className="btn btn-primary broker_link tradeEl"
                        link="https://www.utrade.com.my/"
                        defaultValue="UOB Kay Hian"
                        type="button"
                        onClick={UobKayHian}
                      />
                      <input
                        className="btn btn-primary broker_link tradeEl"
                        style={{ visibility: "hidden" }}
                        link="https://www.utrade.com.my/"
                        defaultValue="Phillip "
                        type="button"
                      />
                    </div>
                  </div>
                </OutsideClickHandler>
              </div>
            </div>
            <p className="section-txt">
              Find the right warrant according to your investment criteria.
            </p>
            <div className="filter">
              <div className="row">
                <div className="col-sm-4">
                  <div className="filter-item">
                    <Select
                      className="warrant-search_dropdown"
                      placeholder={"All Underlying"}
                      value={underlyingSelected}
                      onChange={setUnderlyingSelected}
                      options={underlying}
                      styles={colourStyles2}
                    />
                  </div>
                  <div className="filter-item xs-none">
                    <Select
                      className="warrant-search_dropdown"
                      placeholder={"All Maturity"}
                      value={maturitySelected}
                      isSearchable={false}
                      onChange={setMaturitySelected}
                      options={maturity}
                      styles={colourStyles}
                    />
                  </div>
                  <div className="filter-item xs-none">
                    <Select
                      className="warrant-search_dropdown"
                      placeholder={"All expiry"}
                      value={expirySelected}
                      isSearchable={false}
                      onChange={setExpirySelected}
                      options={expiryDates}
                      styles={colourStyles}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="filter-item">
                    <Select
                      className="warrant-search_dropdown"
                      placeholder={"Call/Put"}
                      value={typeSelected}
                      isSearchable={false}
                      onChange={setTypeSelected}
                      options={callPut}
                      styles={colourStyles}
                    />
                  </div>
                  <div className="filter-item xs-none">
                    <Select
                      className="warrant-search_dropdown"
                      placeholder={"All Eff. Gearing"}
                      value={effectiveGearingSelected}
                      isSearchable={false}
                      onChange={setEffectiveGearingSelected}
                      options={effectiveGearing}
                      styles={colourStyles}
                    />
                  </div>
                  <div className="filter-item xs-none">
                    <Select
                      className="warrant-search_dropdown"
                      placeholder={"All Indicators"}
                      value={indicatorSelected}
                      isSearchable={false}
                      onChange={setIndicatorSelected}
                      options={indicators}
                      styles={colourStyles}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="filter-item xs-none">
                    <Select
                      className="warrant-search_dropdown"
                      placeholder={"All Moneyness"}
                      value={moneynessSelected}
                      isSearchable={false}
                      onChange={setMoneynessSelected}
                      options={moneyness}
                      styles={colourStyles}
                    />
                  </div>
                  <div className="filter-item">
                    <MultiSelect
                      label="Issuer"
                      style={colourStyles1}
                      labelledBy="Issuer"
                      avoidHighlightFirstOption={false}
                      disableSearch="false"
                      options={MACQ}
                      overrideStrings={{
                        allItemsAreSelected: "All issuers",
                        selectAll: "All issuers",
                        Macquarie: "MACQ",
                        someItemsSelected: "",
                      }}
                      defaultValue={{ label: "Ambank", value: "Ambank" }}
                      value={issuerSelected}
                      onChange={(val) => {
                        if (val.length > 0) {
                          setIssuerSelected(val);
                        } else {
                          setIssuerSelected([
                            { label: "MACQ", value: "Macquarie" },
                          ]);
                        }
                      }}
                    />
                  </div>
                  <div className="filter-item-button">
                    <button
                      onClick={() =>
                        getWarrantSearchTable(sortOrderSelected, sortBySelected, false)
                      }
                      type="button"
                      className="btn btn-block btn-primary"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="indicator sm-none xs-none">
              <ul className="indicator-list list-unstyled list-inline">
                <li>
                  <div className="indicator-item small">
                    <span className="icon-hotdw indicator-item-icon" />
                    <span
                      className="ttip"
                      data-tip
                      data-for={"trending-tooltip"}
                    >
                      Trending warrants
                    </span>
                  </div>
                  <ReactTooltip
                    id={"trending-tooltip"}
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    clickable={true}
                  >
                    <div className="bg-tooltip">
                      <p className="tooltip-content">
                        <strong>Trending warrants:</strong> The Trending
                        warrants are selected by Macquarie as the daily
                        highlights for investors. The market maker will aim to
                        provide high liquidity for these warrants and, if
                        possible, will try (but is not obliged) to sell to
                        investors queuing at the market maker’s best bid price.
                      </p>
                    </div>
                  </ReactTooltip>
                </li>
                <li>
                  <div className="indicator-item small">
                    <span className="icon-liquidity indicator-item-icon" />
                    <span
                      className="ttip"
                      data-tip
                      data-for={"highest-liquidity-tooltip"}
                    >
                      Highest liquidity
                    </span>
                  </div>
                  <ReactTooltip
                    id={"highest-liquidity-tooltip"}
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    clickable={true}
                  >
                    <div className="bg-tooltip">
                      <p className="tooltip-content">
                        <strong>Highest liquidity:</strong>
                        Highlights the Macquarie SW in each underlying with the
                        largest volume on the current bid and offer, indicating
                        the highest liquidity. This is often also the most
                        actively traded SW.
                      </p>
                    </div>
                  </ReactTooltip>
                </li>
                <li>
                  <div className="indicator-item small">
                    <span
                      className="icon-expiry indicator-item-icon"
                      data-target="nearexpiry"
                    />
                    <span
                      className="ttip"
                      data-tip
                      data-for={"near-expiry-tooltip"}
                    >
                      Near expiry
                    </span>
                  </div>
                  <ReactTooltip
                    id={"near-expiry-tooltip"}
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    clickable={true}
                  >
                    <div className="bg-tooltip">
                      <p className="tooltip-content">
                        <strong>Near expiry:</strong> Indicates SWs with less
                        than 1 month to expiry. These warrants have higher
                        holding costs due to a higher rate of time decay, so
                        please exercise caution when trading these warrants.
                      </p>
                    </div>
                  </ReactTooltip>
                </li>
                <li>
                  <div className="indicator-item small">
                    <span className="icon-soldout indicator-item-icon" />
                    <span
                      className="ttip"
                      data-tip
                      data-for={"inventory-sold-out-tooltip"}
                    >
                      Inventory sold-out
                    </span>
                  </div>
                  <ReactTooltip
                    id={"inventory-sold-out-tooltip"}
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    clickable={true}
                  >
                    <div className="bg-tooltip">
                      <p className="tooltip-content">
                        <strong>Inventory sold-out:</strong>
                        SWs with this indicator are either sold out or close to
                        being sold out, meaning the market maker may no longer
                        provide an offer price or have widened spreads by
                        raising the offer price. Existing holders are not
                        disadvantaged as the bid price is still maintained at
                        its fair price. However, please exercise caution if you
                        are looking to buy this SW as the market price may be
                        inflated by other investors queuing in the market. Do
                        check the Live Matrix for the warrant’s fair bid price.
                      </p>
                    </div>
                  </ReactTooltip>
                </li>
                <br />
                <li>
                  <div className="indicator-item small">
                    <span className="icon-new indicator-item-icon" />
                    <span
                      className="ttip"
                      data-tip
                      data-for={"new-warrant-tooltip"}
                    >
                      New warrant
                    </span>
                  </div>
                  <ReactTooltip
                    id={"new-warrant-tooltip"}
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    clickable={true}
                  >
                    <div className="bg-tooltip">
                      <p className="tooltip-content">
                        <strong>New warrant:</strong>
                        SWs with this indicator are relatively new, i.e. listed
                        in the last week.{" "}
                      </p>
                    </div>
                  </ReactTooltip>
                </li>
                <li>
                  <div className="indicator-item small">
                    <span className="icon-responsive indicator-item-icon" />
                    <span
                      className="ttip"
                      data-tip
                      data-for={"more-responsive-tooltip"}
                    >
                      More responsive
                    </span>
                  </div>
                  <ReactTooltip
                    id={"more-responsive-tooltip"}
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    clickable={true}
                  >
                    <div className="bg-tooltip">
                      <p className="tooltip-content">
                        <strong>More responsive: </strong>
                        SWs with this indicator are more responsive to price
                        movements in the underlying.
                      </p>
                    </div>
                  </ReactTooltip>
                </li>
                <li>
                  <div className="indicator-item small">
                    <span className="icon-effGearing indicator-item-icon" />
                    <span
                      className="ttip"
                      data-tip
                      data-for={"high-effective-gearing-tooltip"}
                    >
                      High effective gearing
                    </span>
                  </div>
                  <ReactTooltip
                    id={"high-effective-gearing-tooltip"}
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    clickable={true}
                  >
                    <div className="bg-tooltip">
                      <p className="tooltip-content">
                        <strong>High effective gearing:</strong>
                        SWs with this indicator have relatively higher effective
                        gearing level, meaning the SW price will move at a
                        larger percentage relative to a price movement in the
                        underlying, translating to a higher profit potential but
                        also higher level of risk.
                      </p>
                    </div>
                  </ReactTooltip>
                </li>
                <li>
                  <div className="indicator-item small">
                    <span className="icon-timeDecay indicator-item-icon" />
                    <span
                      className="ttip"
                      data-tip
                      data-for={"low-time-Decay-tooltip"}
                    >
                      Low time decay
                    </span>
                  </div>
                  <ReactTooltip
                    id={"low-time-Decay-tooltip"}
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    clickable={true}
                  >
                    <div className="bg-tooltip">
                      <p className="tooltip-content">
                        <strong>Low time decay: </strong>
                        SWs with this indicator have relatively lower time
                        decay, meaning lower holding costs for investors.
                        Generally, all else constant, longer dated SWs have
                        lower time decay than shorter dated SWs and vice versa.
                      </p>
                    </div>
                  </ReactTooltip>
                </li>
              </ul>
            </div>
            <div id="resulttable" className="result">
              <p
                className="sorttip xs-view"
                style={{ marginBottom: "10px", marginTop: "12px" }}
              >
                Click the column header to sort.
              </p>
              <table
                id="faketable"
                className="sm-none xs-none"
                style={{ tableLayout: "fixed", marginBottom: "-1px" }}
              >
                <thead>
                  <tr
                    className="result-hdr2"
                    style={{ height: 0, visibility: "hidden" }}
                  >
                    <th className="bgcolor-04" style={{ width: "10.15%" }} />
                    <th className="bgcolor-04" style={{ width: "6.15%" }} />
                    <th className="bgcolor-04" style={{ width: "6.6%" }} />
                    <th className="bgcolor-04" style={{ width: "4.1%" }} />
                    <th className="bgcolor-04" style={{ width: "6.1%" }} />
                    <th className="bgcolor-04" style={{ width: "4.1%" }} />
                    <th className="bgcolor-04" style={{ width: "6.1%" }} />
                    <th className="bgcolor-04" style={{ width: "5.6%" }} />
                    {/* Market */}
                    <th className="bgcolor-05" style={{ width: "6.1%" }} />
                    <th className="bgcolor-05" style={{ width: "5.1%" }} />
                    <th className="bgcolor-05" style={{ width: "6.1%" }} />
                    <th className="bgcolor-05" style={{ width: "5.1%" }} />
                    <th className="bgcolor-05" style={{ width: "6.1%" }} />
                    <th className="bgcolor-05" style={{ width: "5%" }} />
                    {/* DW */}
                    <th className="bgcolor-06" style={{ width: "5.1%" }} />
                    <th className="bgcolor-06" style={{ width: "4.6%" }} />
                    <th className="bgcolor-06" style={{ width: "6.1%" }} />
                    <th className="bgcolor-06" style={{ width: "6.0%" }} />
                  </tr>
                  <tr className="result-hdr1">
                    <th className="bgcolor-04" colSpan={8}>
                      GENERAL
                    </th>
                    <th className="bgcolor-05" colSpan={6}>
                      MARKET
                    </th>
                    <th className="bgcolor-06" colSpan={4}>
                      WARRANT
                    </th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
              <table id="resulttable-lg" className="table-fixed">
                <thead>
                  <tr className="result-hdr2 sm-none xs-none">
                    {/* GENERAL */}
                    <th className="bgcolor-04" style={{ width: "10.15%" }}>
                      <span className="ttip" data-tip data-for={"warrant-name"}>
                        Warrant name
                      </span>
                      <ReactTooltip
                        id={"warrant-name"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Warrant name:</strong> Each warrant has an
                            assigned name. The name contains information about
                            the warrant.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-04" style={{ width: "6.15%" }}>
                      Indicators
                    </th>
                    <th className="bgcolor-04" style={{ width: "6.6%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"underlying-price"}
                      >
                        Underlying
                        <br />
                        price
                      </span>
                      <ReactTooltip
                        id={"underlying-price"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Underlying price:</strong> The last traded
                            price for the underlying shares.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-04" style={{ width: "4.1%" }}>
                      <span className="ttip" data-tip data-for={"type-tooltip"}>
                        Type
                      </span>
                      <ReactTooltip
                        id={"type-tooltip"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Type:</strong> Calls increase in value as
                            share price rises, Puts increase in value as share
                            price falls.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-04" style={{ width: "6.1%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"exercise-price"}
                      >
                        Exercise
                        <br />
                        price
                      </span>
                      <ReactTooltip
                        id={"exercise-price"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Exercise price:</strong> The price at which
                            you can buy (calls) or sell (puts) the underlying
                            share or index at expiry.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-04" style={{ width: "4.1%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"exercise-ratio"}
                      >
                        Exercise ratio
                      </span>
                      <ReactTooltip
                        id={"exercise-ratio"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Exercise ratio:</strong> The number of
                            warrants that entitles you to one unit of the
                            underlying share or index at expiry.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-04" style={{ width: "6.1%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"expiry-tooltip"}
                      >
                        Expiry
                      </span>
                      <ReactTooltip
                        id={"expiry-tooltip"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Expiry:</strong> The date at which the
                            warrant will expire.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-04" style={{ width: "5.6%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"Issuer-tooltip"}
                      >
                        Issuer
                      </span>
                      <ReactTooltip
                        id={"Issuer-tooltip"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Issuer:</strong> The Bank or Securities
                            company that issues the Warrant.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    {/* Market */}
                    <th className="bgcolor-05" style={{ width: "6.1%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"Bid-volume-tooltip"}
                      >
                        Bid volume
                        <br />
                        ('000)
                      </span>
                      <ReactTooltip
                        id={"Bid-volume-tooltip"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Bid volume:</strong> The volume provided at
                            the current highest bid price for the Warrant.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-05" style={{ width: "5.1%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"Bid-myr-tooltip"}
                      >
                        Bid
                        <br />
                        (MYR)
                      </span>
                      <ReactTooltip
                        id={"Bid-myr-tooltip"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Bid:</strong> The highest current buy price
                            of the Warrant. This is the value that most
                            accurately reflects the 'value' of the Warrant at
                            any point in time.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-05" style={{ width: "6.1%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"Ask-myr-tooltip"}
                      >
                        Ask
                        <br />
                        (MYR)
                      </span>
                      <ReactTooltip
                        id={"Ask-myr-tooltip"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Ask:</strong> The lowest current sell price
                            for the Warrant.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-05" style={{ width: "5.1%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"Ask-volume-tooltip"}
                      >
                        Ask volume
                        <br />
                        ('000)
                      </span>
                      <ReactTooltip
                        id={"Ask-volume-tooltip"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Ask volume:</strong> The volume provided at
                            the current lowest sell price for the Warrant.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-05" style={{ width: "6.1%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"Traded-volume-tooltip"}
                      >
                        Traded volume
                        <br />
                        ('000)
                      </span>
                      <ReactTooltip
                        id={"Traded-volume-tooltip"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Traded volume:</strong> Total volume traded
                            in the Warrant for the current day. A Warrant that
                            has not traded for the day does not necessarily mean
                            that it is not liquid.{" "}
                            <Link
                              target="_blank"
                              to="/edu/market-marker/liquidity-vs-volume"
                            >
                              {" "}
                              Click here{" "}
                            </Link>{" "}
                            for more info about Warrant and liquidity.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-05" style={{ width: "5%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"Price-change-tooltip"}
                      >
                        Price change
                        <br />
                        (%)
                      </span>
                      <ReactTooltip
                        id={"Price-change-tooltip"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Price change:</strong> Current bid price for
                            the Warrant compared to the Prior (previous days
                            close). The bid price of the Warrant 1 minute before
                            close of trading at 5.00pm, will be used for the
                            Prior. For more info{" "}
                            <Link
                              to="/edu/monitoring-price-changes/closing-times"
                              target="_blank"
                            >
                              {" "}
                              click here{" "}
                            </Link>{" "}
                            .
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-06" style={{ width: "5.1%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"Delta-tooltip"}
                      >
                        Delta
                        <br />
                        (%)
                      </span>
                      <ReactTooltip
                        id={"Delta-tooltip"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Delta:</strong> Estimates how a warrant
                            moves in relation to the underlying stock/index.
                            Puts have negative deltas because their prices
                            increase as the underlying stock/index falls. Note,
                            if your warrant has a WPS that this will need to be
                            taken into account.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-06" style={{ width: "4.6%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"gearing-tooltip"}
                      >
                        Eff. gearing
                        <br />
                        (x)
                      </span>
                      <ReactTooltip
                        id={"gearing-tooltip"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Effective gearing:</strong> Indicates your
                            leveraged exposure. An effective gearing of 5X tells
                            you a 1% move in the underlying stock/index results
                            in an approximate 5% move in your Warrant price.{" "}
                            <Link
                              to="/edu/useful-warrant-terms/effective-gearing"
                              target="_blank"
                            >
                              {" "}
                              Click here{" "}
                            </Link>{" "}
                            to learn more about effective gearing.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-06" style={{ width: "6.1%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"sensitivity-tooltip"}
                      >
                        Sensitivity
                      </span>
                      <ReactTooltip
                        id={"sensitivity-tooltip"}
                        delayHide={100}
                        place="left"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Sensitivity:</strong> Shows you how much the
                            underlying stock or index must move for your warrant
                            to move one "tick" or "Spread". For warrants that
                            cost less than RM1, one tick is RM0.005.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-06" style={{ width: "6%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"Implied-volatility"}
                      >
                        Implied volatility
                        <br />
                        (%)
                      </span>
                      <ReactTooltip
                        id={"Implied-volatility"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Implied volatility:</strong> The higher the
                            implied volatility, the higher the price of the
                            Warrant (all other factors held constant).{" "}
                            <Link
                              to="/edu/useful-warrant-terms/implied-volatility"
                              target="_blank"
                            >
                              {" "}
                              Click here{" "}
                            </Link>{" "}
                            to learn more about implied volatility.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                  </tr>
                  <tr className="result-hdr1 sm-view">
                    <th className="bgcolor-04" colSpan={6}>
                      GENERAL
                    </th>
                    <th className="bgcolor-05" colSpan={6}>
                      MARKET
                    </th>
                    <th className="bgcolor-06" colSpan={2}>
                      WARRANT
                    </th>
                  </tr>
                  <tr className="result-hdr2 sm-view">
                    {/* GENERAL */}
                    <th className="bgcolor-04" style={{ width: "6.15%" }}>
                      <span className="ttip" data-tip data-for={"warrant-name"}>
                        Warrant name
                      </span>
                      <ReactTooltip
                        id={"warrant-name"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                        className="sm-none xs-none"
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Warrant name:</strong> Each warrant has an
                            assigned name. The name contains information about
                            the warrant.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th
                      className="bgcolor-04 sm-none xs-none"
                      style={{ width: "6.15%" }}
                    >
                      Indicators
                    </th>
                    <th
                      className="bgcolor-04 sm-none xs-none"
                      style={{ width: "6.6%" }}
                    >
                      <span
                        className="ttip"
                        data-tip
                        data-for={"underlying-price"}
                      >
                        Underlying
                        <br />
                        price
                      </span>
                      <ReactTooltip
                        id={"underlying-price"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                        className="sm-none"
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Underlying price:</strong> The last traded
                            price for the underlying shares.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th
                      className="bgcolor-04 xs-none"
                      style={{ width: "4.1%" }}
                    >
                      <span className="ttip" data-tip data-for={"type-tooltip"}>
                        Type
                      </span>
                      <ReactTooltip
                        id={"type-tooltip"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                        className="sm-none"
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Type:</strong> Calls increase in value as
                            share price rises, Puts increase in value as share
                            price falls.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-04" style={{ width: "6.1%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"exercise-price"}
                      >
                        Ex price
                      </span>
                      <ReactTooltip
                        id={"exercise-price"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                        className="sm-none xs-none"
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Exercise price:</strong> The price at which
                            you can buy (calls) or sell (puts) the underlying
                            share or index at expiry.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th
                      className="bgcolor-04 xs-none"
                      style={{ width: "4.1%" }}
                    >
                      <span
                        className="ttip"
                        data-tip
                        data-for={"exercise-ratio"}
                      >
                        Exercise ratio
                      </span>
                      <ReactTooltip
                        id={"exercise-ratio"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                        className="sm-none"
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Exercise ratio:</strong> The number of
                            warrants that entitles you to one unit of the
                            underlying share or index at expiry.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-04" style={{ width: "6.1%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"expiry-tooltip"}
                      >
                        Expiry
                      </span>
                      <ReactTooltip
                        id={"expiry-tooltip"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                        className="sm-none xs-none"
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Expiry:</strong> The date at which the
                            warrant will expire.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th
                      className="bgcolor-04 xs-none"
                      style={{ width: "4.6%" }}
                    >
                      <span
                        className="ttip"
                        data-tip
                        data-for={"Issuer-tooltip"}
                      >
                        Issuer
                      </span>
                      <ReactTooltip
                        id={"Issuer-tooltip"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                        className="sm-none"
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Issuer:</strong> The Bank or Securities
                            company that issues the Warrant.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    {/* Market */}
                    <th
                      className="bgcolor-05 xs-none"
                      style={{ width: "6.1%" }}
                    >
                      <span
                        className="ttip"
                        data-tip
                        data-for={"Bid-volume-tooltip"}
                      >
                        Bid volume
                        <br />
                        ('000)
                      </span>
                      <ReactTooltip
                        id={"Bid-volume-tooltip"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                        className="sm-none"
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Bid volume:</strong> The volume provided at
                            the current highest bid price for the Warrant.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-05" style={{ width: "5.1%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"Bid-myr-tooltip"}
                      >
                        Bid
                        <br className="xs-none" />
                        <span className="xs-none">(MYR)</span>
                      </span>
                      <ReactTooltip
                        id={"Bid-myr-tooltip"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                        className="sm-none xs-none"
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Bid:</strong> The highest current buy price
                            of the Warrant. This is the value that most
                            accurately reflects the 'value' of the Warrant at
                            any point in time.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th
                      className="bgcolor-05 xs-none"
                      style={{ width: "5.1%" }}
                    >
                      <span
                        className="ttip"
                        data-tip
                        data-for={"Ask-myr-tooltip"}
                      >
                        Ask
                        <br />
                        (MYR)
                      </span>
                      <ReactTooltip
                        id={"Ask-myr-tooltip"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                        className="sm-none"
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Ask:</strong> The lowest current sell price
                            for the Warrant.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th
                      className="bgcolor-05 xs-none"
                      style={{ width: "7.1%" }}
                    >
                      <span
                        className="ttip"
                        data-tip
                        data-for={"Ask-volume-tooltip"}
                      >
                        Ask volume
                        <br />
                        ('000)
                      </span>
                      <ReactTooltip
                        id={"Ask-volume-tooltip"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                        className="sm-none"
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Ask volume:</strong> The volume provided at
                            the current lowest sell price for the Warrant.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-05" style={{ width: "8.1%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"Traded-volume-tooltip"}
                      >
                        <span className="xs-none">Traded</span> volume
                        <br />
                        ('000)
                      </span>
                      <ReactTooltip
                        id={"Traded-volume-tooltip"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                        className="sm-none xs-none"
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Traded volume:</strong> Total volume traded
                            in the Warrant for the current day. A Warrant that
                            has not traded for the day does not necessarily mean
                            that it is not liquid.{" "}
                            <Link
                              target="_blank"
                              to="/edu/market-marker/liquidity-vs-volume"
                            >
                              {" "}
                              Click here{" "}
                            </Link>{" "}
                            for more info about Warrant and liquidity.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-05" style={{ width: "7%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"Price-change-tooltip"}
                      >
                        Price change
                        <br />
                        (%)
                      </span>
                      <ReactTooltip
                        id={"Price-change-tooltip"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                        className="sm-none xs-none"
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Price change:</strong> Current bid price for
                            the Warrant compared to the Prior (previous days
                            close). The bid price of the Warrant 1 minute before
                            close of trading at 5.00pm, will be used for the
                            Prior. For more info{" "}
                            <Link
                              to="/edu/monitoring-price-changes/closing-times"
                              target="_blank"
                            >
                              {" "}
                              click here{" "}
                            </Link>
                            .
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    {/* DW */}
                    <th
                      className="bgcolor-06 sm-none xs-none"
                      style={{ width: "5.1%" }}
                    >
                      <span
                        className="ttip"
                        data-tip
                        data-for={"Delta-tooltip"}
                      >
                        Delta
                        <br />
                        (%)
                      </span>
                      <ReactTooltip
                        id={"Delta-tooltip"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                        className="sm-none"
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Delta:</strong> Estimates how a warrant
                            moves in relation to the underlying stock/index.
                            Puts have negative deltas because their prices
                            increase as the underlying stock/index falls. Note,
                            if your warrant has a WPS that this will need to be
                            taken into account.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th
                      className="bgcolor-06 xs-none"
                      style={{ width: "6.6%" }}
                    >
                      <span
                        className="ttip"
                        data-tip
                        data-for={"gearing-tooltip"}
                      >
                        Eff. gearing
                        <br />
                        (x)
                      </span>
                      <ReactTooltip
                        id={"gearing-tooltip"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                        className="sm-none"
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Effective gearing:</strong> Indicates your
                            leveraged exposure. An effective gearing of 5X tells
                            you a 1% move in the underlying stock/index results
                            in an approximate 5% move in your Warrant price.{" "}
                            <Link
                              to="/edu/useful-warrant-terms/effective-gearing"
                              target="_blank"
                            >
                              {" "}
                              Click here{" "}
                            </Link>{" "}
                            to learn more about effective gearing.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th
                      className="bgcolor-06 sm-none xs-none"
                      style={{ width: "6.1%" }}
                    >
                      <span
                        className="ttip"
                        data-tip
                        data-for={"sensitivity-tooltip"}
                      >
                        Sensitivity
                      </span>
                      <ReactTooltip
                        id={"sensitivity-tooltip"}
                        delayHide={100}
                        place="left"
                        type="warning"
                        effect="solid"
                        clickable={true}
                        className="sm-none"
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Sensitivity:</strong> Shows you how much the
                            underlying stock or index must move for your warrant
                            to move one "tick" or "Spread". For warrants that
                            cost less than RM1, one tick is RM0.005.
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-06 xs-none" style={{ width: "8%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"Implied-volatility"}
                      >
                        Implied volatility
                        <br />
                        (%)
                      </span>
                      <ReactTooltip
                        id={"Implied-volatility"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                        className="sm-none"
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Implied volatility:</strong> The higher the
                            implied volatility, the higher the price of the
                            Warrant (all other factors held constant).{" "}
                            <Link
                              to="/edu/useful-warrant-terms/implied-volatility"
                              target="_blank"
                            >
                              {" "}
                              Click here{" "}
                            </Link>{" "}
                            to learn more about implied volatility.{" "}
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                  </tr>
                  <tr className="result-sort">
                    <th>
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "wcode")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "wcode")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="sm-none xs-none"></th>
                    <th className="sm-none xs-none">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "uprice")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "uprice")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="xs-none">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "type")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "type")}
                        className="result-sort-up"
                      />
                    </th>
                    <th>
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "ep")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "ep")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="xs-none">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "dwps")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "dwps")}
                        className="result-sort-up"
                      />
                    </th>
                    <th>
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "mdate")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "mdate")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="xs-none">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "isr")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "isr")}
                        className="result-sort-up"
                      />
                    </th>
                    {/* Market */}
                    <th className="xs-none">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "bv")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "bv")}
                        className="result-sort-up"
                      />
                    </th>
                    <th>
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "bp")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "bp")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="xs-none">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "ap")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "ap")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="xs-none">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "av")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "av")}
                        className="result-sort-up"
                      />
                    </th>
                    <th>
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "tv")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "tv")}
                        className="result-sort-up"
                      />
                    </th>
                    <th>
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "pc")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "pc")}
                        className="result-sort-up"
                      />
                    </th>
                    {/* DW */}
                    <th className="sm-none xs-none">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "dlt")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "dlt")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="xs-none">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "eg")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "eg")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="sm-none xs-none">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "se")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "se")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="xs-none">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "iv")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "iv")}
                        className="result-sort-up"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {warrantSearchData.length > 0 && !isLoading ? ( warrantSearchData.map((warrantData, idx) => (
                      <tr
                        key={idx}
                        className={
                          warrantData.issuer == "Macquarie"
                            ? "rowtmpl-lg text-bold"
                            : "rowtmpl-lg"
                        }
                        idx={idx}
                      >
                        <td className="col-dwSymbol">
                          <Link
                            to={`${"/tools/livematrix/"}${warrantData.ticker}`}
                          >
                            {warrantData && warrantData.dsply_nmll}
                          </Link>
                        </td>
                        <td className="col-indicator sm-none xs-none">
                          {warrantData && warrantData.HOTLIST === true && (
                            <>
                              <span
                                className="indicator-item-icon icon-hotdw ttip"
                                data-tip
                                data-for={"trending-tooltip-1"}
                              />
                              <ReactTooltip
                                id={"trending-tooltip-1"}
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                              >
                                <div className="bg-tooltip">
                                  <p className="tooltip-content">
                                    <strong>Trending warrants:</strong> The
                                    Trending warrants are selected by Macquarie
                                    as the daily highlights for investors. The
                                    market maker will aim to provide high
                                    liquidity for these warrants and, if
                                    possible, will try (but is not obliged) to
                                    sell to investors queuing at the market
                                    maker’s best bid price.
                                  </p>
                                </div>
                              </ReactTooltip>
                            </>
                          )}
                          {warrantData && warrantData.HIGHLQDY === true && (
                            <>
                              <span
                                className="indicator-item-icon icon-liquidity ttip"
                                data-tip
                                data-for={"highest-liquidity-tooltip-1"}
                              />
                              <ReactTooltip
                                id={"highest-liquidity-tooltip-1"}
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                              >
                                <div className="bg-tooltip">
                                  <p className="tooltip-content">
                                    <strong>Highest liquidity:</strong>
                                    Highlights the Macquarie SW in each
                                    underlying with the largest volume on the
                                    current bid and offer, indicating the
                                    highest liquidity. This is often also the
                                    most actively traded SW.
                                  </p>
                                </div>
                              </ReactTooltip>
                            </>
                          )}
                          {warrantData && warrantData.APPCHEXPRY === true && (
                            <>
                              <span
                                className="indicator-item-icon icon-expiry ttip"
                                data-target="timeDecay"
                                data-tip
                                data-for={"near-expiry-tooltip-1"}
                              />
                              <ReactTooltip
                                id={"near-expiry-tooltip-1"}
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                              >
                                <div className="bg-tooltip">
                                  <p className="tooltip-content">
                                    <strong>Near expiry:</strong> Indicates SWs
                                    with less than 1 month to expiry. These
                                    warrants have higher holding costs due to a
                                    higher rate of time decay, so please
                                    exercise caution when trading these
                                    warrants.
                                  </p>
                                </div>
                              </ReactTooltip>
                            </>
                          )}
                          {warrantData && warrantData.SOLDOUT === true && (
                            <>
                              <span
                                className="indicator-item-icon icon-soldout ttip"
                                data-tip
                                data-for={"inventory-sold-out-tooltip-1"}
                              />
                              <ReactTooltip
                                id={"inventory-sold-out-tooltip-1"}
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                              >
                                <div className="bg-tooltip">
                                  <p className="tooltip-content">
                                    <strong>Inventory sold-out:</strong>
                                    SWs with this indicator are either sold out
                                    or close to being sold out, meaning the
                                    market maker may no longer provide an offer
                                    price or have widened spreads by raising the
                                    offer price. Existing holders are not
                                    disadvantaged as the bid price is still
                                    maintained at its fair price. However,
                                    please exercise caution if you are looking
                                    to buy this SW as the market price may be
                                    inflated by other investors queuing in the
                                    market. Do check the Live Matrix for the
                                    warrant’s fair bid price.
                                  </p>
                                </div>
                              </ReactTooltip>
                            </>
                          )}
                          {warrantData && warrantData.NEWWARRANT === true && (
                            <>
                              <span
                                className="indicator-item-icon icon-new ttip"
                                data-tip
                                data-for={"new-warrant-tooltip-1"}
                              />
                              <ReactTooltip
                                id={"new-warrant-tooltip-1"}
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                              >
                                <div className="bg-tooltip">
                                  <p className="tooltip-content">
                                    <strong>New warrant:</strong>
                                    SWs with this indicator are relatively new,
                                    i.e. listed in the last week.
                                  </p>
                                </div>
                              </ReactTooltip>
                            </>
                          )}
                          {warrantData &&
                            warrantData.MORERESPONSIVE === true && (
                              <>
                                <span
                                  className="indicator-item-icon icon-responsive ttip"
                                  data-tip
                                  data-for={"more-responsive-tooltip-1"}
                                />
                                <ReactTooltip
                                  id={"more-responsive-tooltip-1"}
                                  delayHide={100}
                                  place="right"
                                  type="warning"
                                  effect="solid"
                                >
                                  <div className="bg-tooltip">
                                    <p className="tooltip-content">
                                      <strong>More responsive: </strong>
                                      SWs with this indicator are more
                                      responsive to price movements in the
                                      underlying.
                                    </p>
                                  </div>
                                </ReactTooltip>
                              </>
                            )}
                          {warrantData &&
                            warrantData.HIGHEFFECTIVEGEARING === true && (
                              <>
                                <span
                                  className="indicator-item-icon icon-effGearing ttip"
                                  data-tip
                                  data-for={"high-effective-gearing-tooltip-1"}
                                />
                                <ReactTooltip
                                  id={"high-effective-gearing-tooltip-1"}
                                  delayHide={100}
                                  place="right"
                                  type="warning"
                                  effect="solid"
                                >
                                  <div className="bg-tooltip">
                                    <p className="tooltip-content">
                                      <strong>High effective gearing:</strong>
                                      SWs with this indicator have relatively
                                      higher effective gearing level, meaning
                                      the SW price will move at a larger
                                      percentage relative to a price movement in
                                      the underlying, translating to a higher
                                      profit potential but also higher level of
                                      risk.
                                    </p>
                                  </div>
                                </ReactTooltip>
                              </>
                            )}
                          {warrantData && warrantData.LOWTIMEDECAY === true && (
                            <>
                              <span
                                className="indicator-item-icon icon-timeDecay ttip"
                                data-tip
                                data-for={"low-time-Decay-tooltip-1"}
                              />
                              <ReactTooltip
                                id={"low-time-Decay-tooltip-1"}
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                              >
                                <div className="bg-tooltip">
                                  <p className="tooltip-content">
                                    <strong>Low time decay: </strong>
                                    SWs with this indicator have relatively
                                    lower time decay, meaning lower holding
                                    costs for investors. Generally, all else
                                    constant, longer dated SWs have lower time
                                    decay than shorter dated SWs and vice versa.
                                  </p>
                                </div>
                              </ReactTooltip>
                            </>
                          )}
                        </td>
                        <td className="col-underlying-price sm-none xs-none">
                          {warrantData && warrantData.underlying_price}
                        </td>
                        <td className="col-type xs-none">{warrantData.type}</td>
                        <td className="col-exercisePrice">
                          {warrantData.exercisePrice}
                        </td>
                        <td className="col-dwps xs-none">
                          {warrantData.conv_ratio}
                        </td>
                        <td className="col-maturity">
                          {warrantData && warrantData.maturity}
                        </td>
                        <td className="col-issuer bordered xs-none">
                          {warrantData.issuer === "Macquarie"
                            ? "MACQ"
                            : warrantData.issuer}
                        </td>
                        <td className="col-bidVolume  xs-none">
                          {warrantData && warrantData.bidVolume_f}
                        </td>
                        <td className="col-bidPrice">
                          {(warrantData && warrantData.bidPrice_f) || "-"}
                        </td>
                        <td className="col-askPrice xs-none">
                          {warrantData && warrantData.askPrice_f}
                        </td>
                        <td className="col-askVolume xs-none">
                          {warrantData && warrantData.askVolume_f}
                        </td>
                        <td className="col-tradeVolume">
                          {warrantData && warrantData.tradeVolume_f}
                        </td>
                        <td
                          className={setClass(
                            warrantData && warrantData.priceChange_f
                          )}
                        >
                          {(warrantData && warrantData.priceChange_f) || "-"}
                        </td>
                        <td className="col-delta val sm-none xs-none">
                          {warrantData && warrantData.delta}
                        </td>
                        <td className="col-effectiveGearing xs-none">
                          {warrantData.effectiveGearing}
                        </td>
                        <td className="col-sensitivity sm-none xs-none">
                          {warrantData.sensitivity}
                        </td>
                        <td className="col-impliedVolalitiy xs-none">
                          {impliedVolalitiyData(warrantData)}
                        </td>
                      </tr>
                    ))
                  ) : !isLoading ? (
                    <tr className="emptymsg">
                      <td colSpan="18">No data</td>
                    </tr>
                  ) : "" }
                </tbody>
              </table>
              {isLoading && (
                <div className="loader-div">
                  <LoaderSpin isLoading={isLoading} isVisible />
                </div>
              )}

              <div className="result-disclaimer sm-none xs-none">
                <p className="small" style={{fontWeight: 'bold'}}>
                The price change 
                  (%) is calculated using the current bid price
                  for the warrant compared to the previous day’s closing bid
                  price. At the end of each trading day, we capture the bid
                  price of the warrant slightly before the close of trading and
                  use this to represent the 'closing price'. The relevant times
                  are:
                </p>
                <ul  style={{fontWeight: 'bold'}}>
                  <li>4:43pm for Malaysia underlyings and FBM KLCI warrants</li>
                  <li>3:58pm for Hong Kong shares, China A50 and GLDHK warrants</li>
                  <li>4:28pm for HSI and HSTECH warrants</li>
                  <li>4:43pm for Nikkei Stock Average (Nikkei 225) Index warrants</li>
                  <li>4:43pm for <b>U.S. index </b>warrants</li>
                </ul>
                <p className="small"  style={{fontWeight: 'bold'}}>
                  This will provide the most accurate reference point for
                  investors to track price movements of <b>structured warrants. </b> For more
                  info{" "}
                  <Link
                    to="/edu/monitoring-price-changes/closing-times"
                    target="_blank"
                  >
                    {" "}
                    click here
                  </Link>
                  .<br />
                  <br />
                  The Exercise Prices and Exercise Ratio in the above table in
                  some cases have been rounded to fit the page. Please click on
                  the code to go to the Warrant terms page for the full
                  rounding.
                </p>
                <hr />
                <p className="small"  style={{fontWeight: 'bold'}}>
                  All market data is delayed 15 mins unless otherwise indicated
                  by timestamp
                </p>
              </div>

              <div className="indicatortbl md-up-none">
                <table className="sm-view">
                  <tbody>
                    <tr>
                      <td>
                        <div className="indicator-item small">
                          <span className="icon-hotdw indicator-item-icon" />
                          Trending warrants
                        </div>
                      </td>
                      <td>
                        <div className="indicator-item small">
                          <span className="icon-liquidity indicator-item-icon" />
                          Highest liquidity
                        </div>
                      </td>
                      <td>
                        <div className="indicator-item small">
                          <span className="icon-expiry indicator-item-icon" />
                          Near expiry
                        </div>
                      </td>
                      <td>
                        <div className="indicator-item small">
                          <span className="icon-soldout indicator-item-icon" />
                          Inventory sold-out
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="indicator-item small">
                          <span className="icon-new indicator-item-icon" />
                          New warrant
                        </div>
                      </td>
                      <td>
                        <div className="indicator-item small">
                          <span className="icon-responsive indicator-item-icon" />
                          More responsive
                        </div>
                      </td>
                      <td>
                        <div className="indicator-item small">
                          <span className="icon-effGearing indicator-item-icon" />
                          High effective gearing
                        </div>
                      </td>
                      <td>
                        <div className="indicator-item small">
                          <span className="icon-timeDecay indicator-item-icon" />
                          Low time decay
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="indicatortbl xs-view">
                  <tbody>
                    <tr>
                      <td>
                        <div className="indicator-item small">
                          <span className="icon-hotdw indicator-item-icon" />
                          Trending warrants
                        </div>
                      </td>
                      <td>
                        <div className="indicator-item small">
                          <span className="icon-liquidity indicator-item-icon" />
                          Highest liquidity
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="indicator-item small">
                          <span className="icon-expiry indicator-item-icon" />
                          Near expiry
                        </div>
                      </td>
                      <td>
                        <div className="indicator-item small">
                          <span className="icon-soldout indicator-item-icon" />
                          Inventory sold-out
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="indicator-item small">
                          <span className="icon-new indicator-item-icon" />
                          New warrant
                        </div>
                      </td>
                      <td>
                        <div className="indicator-item small">
                          <span className="icon-responsive indicator-item-icon" />
                          More responsive
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="indicator-item small">
                          <span className="icon-effGearing indicator-item-icon" />
                          High effective gearing
                        </div>
                      </td>
                      <td>
                        <div className="indicator-item small">
                          <span className="icon-timeDecay indicator-item-icon" />
                          Low time decay
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div id="defination" className="panel-group md-up-none">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <button type="button" onClick={() => showNewBox()}>
                      <span className="glyphicon glyphicon glyphicon-question-sign pull-right" />
                      <div
                        id="collapse_title3"
                        className="panel-title text-center color-primary"
                      >
                        <span>Definition &amp; disclaimer</span>
                        <img src="/img/bullet/question.png" alt="Image" />
                      </div>
                    </button>
                  </div>
                  <div
                    id="collapse_dwcompare3"
                    className={`${boxVisibility}`}
                    aria-expanded="true"
                  >
                    <table className="table table-striped">
                      <tbody>
                        <tr>
                          <td>Warrant name</td>
                          <td>
                            Each warrant has an assigned name. The name contains
                            information about the warrant.
                          </td>
                        </tr>
                        <tr className="xs-none">
                          <td>Type</td>
                          <td>
                            Calls increase in value as share price rises, Puts
                            increase in value as share price falls.
                          </td>
                        </tr>
                        <tr>
                          <td>Exercise price</td>
                          <td>
                            The price at which you can buy (calls) or sell
                            (puts) the underlying
                            <br /> share or index at expiry.
                          </td>
                        </tr>
                        <tr className="xs-none">
                          <td>Exercise ratio</td>
                          <td>
                            The number of warrants that entitles you to one unit
                            of the underlying share or index at expiry.
                          </td>
                        </tr>
                        <tr>
                          <td>Expiry</td>
                          <td>The date at which the warrant will expire.</td>
                        </tr>
                        <tr className="xs-none">
                          <td>Issuer</td>
                          <td>
                            The Bank or Securities company that issues the
                            Warrant.
                          </td>
                        </tr>
                        <tr>
                          <td>Traded volume</td>
                          <td>
                            Total volume traded in the Warrant for the current
                            day. A Warrant that has not traded for the day does
                            not necessarily mean that it is not liquid.{" "}
                            <Link target="_blank" to="/edu/market-marker">
                              Click here
                            </Link>{" "}
                            for more info about Warrant and liquidity.
                          </td>
                        </tr>
                        <tr className="xs-none">
                          <td>Bid volume</td>
                          <td>
                            The volume provided at the current highest bid price
                            for the Warrant.
                          </td>
                        </tr>
                        <tr>
                          <td>Bid</td>
                          <td>
                            The highest current buy price of the Warrant. This
                            is the value that most accurately reflects the
                            'value' of the Warrant at any point in time.
                          </td>
                        </tr>
                        <tr className="xs-none">
                          <td>Ask volume</td>
                          <td>
                            The volume provided at the current lowest sell price
                            for the Warrant.
                          </td>
                        </tr>
                        <tr className="xs-none">
                          <td>Ask</td>
                          <td>
                            The lowest current sell price for the Warrant.
                          </td>
                        </tr>
                        <tr className="xs-none">
                          <td>Price change</td>
                          <td>
                            Current bid price for the Warrant compared to the
                            Prior (previous days close). The bid price of the
                            Warrant 1 minute before close of trading at 5.00pm,
                            will be used for the Prior. For more info{" "}
                            <Link
                              target="_blank"
                              to="/edu/monitoring-price-changes/closing-times"
                            >
                              click here
                            </Link>
                            .
                          </td>
                        </tr>
                        <tr className="xs-none">
                          <td>Effective gearing</td>
                          <td>
                            Indicates your leveraged exposure. An effective
                            gearing of 5X tells you a 1% move in the underlying
                            stock/index results in an approximate 5% move in
                            your Warrant price.{" "}
                            <Link
                              target="_blank"
                              to="/edu/useful-warrant-terms/effective-gearing"
                            >
                              Click here
                            </Link>{" "}
                            to learn more about effective gearing.
                          </td>
                        </tr>
                        <tr className="xs-none">
                          <td>Implied volatility</td>
                          <td>
                            The higher the implied volatility, the higher the
                            price of the Warrant (all other factors held
                            constant).{" "}
                            <Link
                              target="_blank"
                              to="/edu/useful-warrant-terms/implied-volatility"
                            >
                              Click here
                            </Link>{" "}
                            to learn more about implied volatility.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p className="small"  style={{fontWeight: 'bold'}}>
                    The price change (%) is calculated using the current bid
                      price for the warrant compared to the previous day’s
                      closing bid price. At the end of each trading day, we
                      capture the bid price of the warrant slightly before the
                      close of trading and use this to represent the 'closing
                      price'. The relevant times are:
                    </p>
                    <ul  style={{fontWeight: 'bold'}}>
                      
                      <li>4:43pm for Malaysia underlyings and FBM KLCI warrants</li>
                      <li>3:58pm for Hong Kong shares and China A50 warrants</li>
                      <li>4:28pm for HSI and HSTECH warrants</li>
                      <li>4:43pm for <b>U.S. index </b>warrants</li>
                    </ul>
                    <p className="small"  style={{fontWeight: 'bold'}}>
                      This will provide the most accurate reference point for
                      investors to track price movements of the Warrant. For
                      more info{" "}
                      <Link
                        to="/edu/monitoring-price-changes/closing-times"
                        target="_blank"
                      >
                        click here
                      </Link>
                      .<br />
                      <br />
                      The Exercise Prices and Exercise Ratio in the above table
                      in some cases have been rounded to fit the page. Please
                      click on the code to go to the Warrant terms page for the
                      full rounding.
                    </p>
                    <hr />
                    <p className="small"  style={{fontWeight: 'bold'}}>
                      All market data is delayed 15 mins unless otherwise
                      indicated by timestamp
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="stickerheader" style={{ display: headerColor }}>
          <div className="container-fluid">
            <div className="section" style={{ paddingTop: 0 }}>
              <div className="result">
                <table
                  id="faketable"
                  className="sm-none xs-none"
                  style={{ tableLayout: "fixed", marginBottom: "-1px" }}
                >
                  <thead>
                    <tr
                      className="result-hdr2"
                      style={{ height: 0, visibility: "hidden" }}
                    >
                      {/* GENERAL */}
                      <th className="bgcolor-04" style={{ width: "10.15%" }} />
                      <th className="bgcolor-04" style={{ width: "6.15%" }} />
                      <th className="bgcolor-04" style={{ width: "6.6%" }} />
                      <th className="bgcolor-04" style={{ width: "4.1%" }} />
                      <th className="bgcolor-04" style={{ width: "6.1%" }} />
                      <th className="bgcolor-04" style={{ width: "4.1%" }} />
                      <th className="bgcolor-04" style={{ width: "6.1%" }} />
                      <th className="bgcolor-04" style={{ width: "5.6%" }} />
                      {/* Market */}
                      <th className="bgcolor-05" style={{ width: "6.1%" }} />
                      <th className="bgcolor-05" style={{ width: "5.1%" }} />
                      <th className="bgcolor-05" style={{ width: "6.1%" }} />
                      <th className="bgcolor-05" style={{ width: "5.1%" }} />
                      <th className="bgcolor-05" style={{ width: "6.1%" }} />
                      <th className="bgcolor-05" style={{ width: "5%" }} />
                      {/* DW */}
                      <th className="bgcolor-06" style={{ width: "5.1%" }} />
                      <th className="bgcolor-06" style={{ width: "4.6%" }} />
                      <th className="bgcolor-06" style={{ width: "6.1%" }} />
                      <th className="bgcolor-06" style={{ width: "6.0%" }} />
                    </tr>
                    <tr className="result-hdr1">
                      <th className="bgcolor-04" colSpan={8}>
                        GENERAL
                      </th>
                      <th className="bgcolor-05" colSpan={6}>
                        MARKET
                      </th>
                      <th className="bgcolor-06" colSpan={4}>
                        WARRANT
                      </th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
                <table id="resulttable-lg" className="table-fixed">
                  <thead>
                    <tr className="result-hdr2 sm-none xs-none">
                      {/* GENERAL */}
                      <th className="bgcolor-04" style={{ width: "10.15%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"warrant-name"}
                        >
                          Warrant name
                        </span>
                        <ReactTooltip
                          id={"warrant-name"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Warrant name:</strong> Each warrant has an
                              assigned name. The name contains information about
                              the warrant.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th className="bgcolor-04" style={{ width: "6.15%" }}>
                        Indicators
                      </th>
                      <th className="bgcolor-04" style={{ width: "6.6%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"underlying-price"}
                        >
                          Underlying
                          <br />
                          price
                        </span>
                        <ReactTooltip
                          id={"underlying-price"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Underlying price:</strong> The last traded
                              price for the underlying shares.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th className="bgcolor-04" style={{ width: "4.1%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"type-tooltip"}
                        >
                          Type
                        </span>
                        <ReactTooltip
                          id={"type-tooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Type:</strong> Calls increase in value as
                              share price rises, Puts increase in value as share
                              price falls.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th className="bgcolor-04" style={{ width: "6.1%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"exercise-price"}
                        >
                          Exercise
                          <br />
                          price
                        </span>
                        <ReactTooltip
                          id={"exercise-price"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Exercise price:</strong> The price at
                              which you can buy (calls) or sell (puts) the
                              underlying share or index at expiry.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th className="bgcolor-04" style={{ width: "4.1%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"exercise-ratio"}
                        >
                          Exercise ratio
                        </span>
                        <ReactTooltip
                          id={"exercise-ratio"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Exercise ratio:</strong> The number of
                              warrants that entitles you to one unit of the
                              underlying share or index at expiry.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th className="bgcolor-04" style={{ width: "6.1%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"expiry-tooltip"}
                        >
                          Expiry
                        </span>
                        <ReactTooltip
                          id={"expiry-tooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Expiry:</strong> The date at which the
                              warrant will expire.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th className="bgcolor-04" style={{ width: "5.6%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"Issuer-tooltip"}
                        >
                          Issuer
                        </span>
                        <ReactTooltip
                          id={"Issuer-tooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Issuer:</strong> The Bank or Securities
                              company that issues the Warrant.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      {/* Market */}
                      <th className="bgcolor-05" style={{ width: "6.1%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"Bid-volume-tooltip"}
                        >
                          Bid volume
                          <br />
                          ('000)
                        </span>
                        <ReactTooltip
                          id={"Bid-volume-tooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Bid volume:</strong> The volume provided
                              at the current highest bid price for the Warrant.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th className="bgcolor-05" style={{ width: "5.1%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"Bid-myr-tooltip"}
                        >
                          Bid
                          <br />
                          (MYR)
                        </span>
                        <ReactTooltip
                          id={"Bid-myr-tooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Bid:</strong> The highest current buy
                              price of the Warrant. This is the value that most
                              accurately reflects the 'value' of the Warrant at
                              any point in time.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th className="bgcolor-05" style={{ width: "6.1%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"Ask-myr-tooltip"}
                        >
                          Ask
                          <br />
                          (MYR)
                        </span>
                        <ReactTooltip
                          id={"Ask-myr-tooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Ask:</strong> The lowest current sell
                              price for the Warrant.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th className="bgcolor-05" style={{ width: "5.1%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"Ask-volume-tooltip"}
                        >
                          Ask volume
                          <br />
                          ('000)
                        </span>
                        <ReactTooltip
                          id={"Ask-volume-tooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Ask volume:</strong> The volume provided
                              at the current lowest sell price for the Warrant.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th className="bgcolor-05" style={{ width: "6.1%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"Traded-volume-tooltip"}
                        >
                          Traded volume
                          <br />
                          ('000)
                        </span>
                        <ReactTooltip
                          id={"Traded-volume-tooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Traded volume:</strong> Total volume
                              traded in the Warrant for the current day. A
                              Warrant that has not traded for the day does not
                              necessarily mean that it is not liquid.{" "}
                              <Link
                                target="_blank"
                                to="/edu/market-marker/liquidity-vs-volume"
                              >
                                {" "}
                                Click here{" "}
                              </Link>{" "}
                              for more info about Warrant and liquidity.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th className="bgcolor-05" style={{ width: "5%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"Price-change-tooltip"}
                        >
                          Price change
                          <br />
                          (%)
                        </span>
                        <ReactTooltip
                          id={"Price-change-tooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Price change:</strong> Current bid price
                              for the Warrant compared to the Prior (previous
                              days close). The bid price of the Warrant 1 minute
                              before close of trading at 5.00pm, will be used
                              for the Prior. For more info{" "}
                              <Link
                                to="/edu/monitoring-price-changes/closing-times"
                                target="_blank"
                              >
                                {" "}
                                click here{" "}
                              </Link>{" "}
                              .
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      {/* DW */}
                      <th className="bgcolor-06" style={{ width: "5.1%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"Delta-tooltip"}
                        >
                          Delta
                          <br />
                          (%)
                        </span>
                        <ReactTooltip
                          id={"Delta-tooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Delta:</strong> Estimates how a warrant
                              moves in relation to the underlying stock/index.
                              Puts have negative deltas because their prices
                              increase as the underlying stock/index falls.
                              Note, if your warrant has a WPS that this will
                              need to be taken into account.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th className="bgcolor-06" style={{ width: "4.6%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"gearing-tooltip"}
                        >
                          Eff. gearing
                          <br />
                          (x)
                        </span>
                        <ReactTooltip
                          id={"gearing-tooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Effective gearing:</strong> Indicates your
                              leveraged exposure. An effective gearing of 5X
                              tells you a 1% move in the underlying stock/index
                              results in an approximate 5% move in your Warrant
                              price.{" "}
                              <Link
                                to="/edu/useful-warrant-terms/effective-gearing"
                                target="_blank"
                              >
                                {" "}
                                Click here{" "}
                              </Link>{" "}
                              to learn more about effective gearing.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th className="bgcolor-06" style={{ width: "6.1%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"sensitivity-tooltip"}
                        >
                          Sensitivity
                        </span>
                        <ReactTooltip
                          id={"sensitivity-tooltip"}
                          delayHide={100}
                          place="left"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Sensitivity:</strong> Shows you how much
                              the underlying stock or index must move for your
                              warrant to move one "tick" or "Spread". For
                              warrants that cost less than RM1, one tick is
                              RM0.005.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th className="bgcolor-06" style={{ width: "6%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"Implied-volatility"}
                        >
                          Implied volatility
                          <br />
                          (%)
                        </span>
                        <ReactTooltip
                          id={"Implied-volatility"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Implied volatility:</strong> The higher
                              the implied volatility, the higher the price of
                              the Warrant (all other factors held constant).{" "}
                              <Link
                                to="/edu/useful-warrant-terms/implied-volatility"
                                target="_blank"
                              >
                                {" "}
                                Click here{" "}
                              </Link>{" "}
                              to learn more about implied volatility.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                    </tr>
                    <tr className="result-hdr1 sm-view">
                      <th className="bgcolor-04" colSpan={6}>
                        GENERAL
                      </th>
                      <th className="bgcolor-05" colSpan={6}>
                        MARKET
                      </th>
                      <th className="bgcolor-06" colSpan={2}>
                        WARRANT
                      </th>
                    </tr>
                    <tr className="result-hdr2 sm-view">
                      {/* GENERAL */}
                      <th className="bgcolor-04" style={{ width: "12.15%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"warrant-name"}
                        >
                          Warrant name
                        </span>
                        <ReactTooltip
                          id={"warrant-name"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                          className="sm-none xs-none"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Warrant name:</strong> Each warrant has an
                              assigned name. The name contains information about
                              the warrant.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th
                        className="bgcolor-04 sm-none xs-none"
                        style={{ width: "6.15%" }}
                      >
                        Indicators
                      </th>
                      <th
                        className="bgcolor-04 sm-none xs-none"
                        style={{ width: "6.6%" }}
                      >
                        <span
                          className="ttip"
                          data-tip
                          data-for={"underlying-price"}
                        >
                          Underlying
                          <br />
                          price
                        </span>
                        <ReactTooltip
                          id={"underlying-price"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                          className="sm-none"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Underlying price:</strong> The last traded
                              price for the underlying shares.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th
                        className="bgcolor-04 xs-none"
                        style={{ width: "4.1%" }}
                      >
                        <span
                          className="ttip"
                          data-tip
                          data-for={"type-tooltip"}
                        >
                          Type
                        </span>
                        <ReactTooltip
                          id={"type-tooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                          className="sm-none"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Type:</strong> Calls increase in value as
                              share price rises, Puts increase in value as share
                              price falls.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th className="bgcolor-04" style={{ width: "6.1%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"exercise-price"}
                        >
                          Ex price
                        </span>
                        <ReactTooltip
                          id={"exercise-price"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                          className="sm-none xs-none"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Exercise price:</strong> The price at
                              which you can buy (calls) or sell (puts) the
                              underlying share or index at expiry.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th
                        className="bgcolor-04 xs-none"
                        style={{ width: "4.1%" }}
                      >
                        <span
                          className="ttip"
                          data-tip
                          data-for={"exercise-ratio"}
                        >
                          Exercise ratio
                        </span>
                        <ReactTooltip
                          id={"exercise-ratio"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                          className="sm-none"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Exercise ratio:</strong> The number of
                              warrants that entitles you to one unit of the
                              underlying share or index at expiry.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th className="bgcolor-04" style={{ width: "6.1%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"expiry-tooltip"}
                        >
                          Expiry
                        </span>
                        <ReactTooltip
                          id={"expiry-tooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                          className="sm-none xs-none"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Expiry:</strong> The date at which the
                              warrant will expire.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th
                        className="bgcolor-04 xs-none"
                        style={{ width: "4.6%" }}
                      >
                        <span
                          className="ttip"
                          data-tip
                          data-for={"Issuer-tooltip"}
                        >
                          Issuer
                        </span>
                        <ReactTooltip
                          id={"Issuer-tooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                          className="sm-none"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Issuer:</strong> The Bank or Securities
                              company that issues the Warrant.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      {/* Market */}
                      <th
                        className="bgcolor-05 xs-none"
                        style={{ width: "6.1%" }}
                      >
                        <span
                          className="ttip"
                          data-tip
                          data-for={"Bid-volume-tooltip"}
                        >
                          Bid volume
                          <br />
                          ('000)
                        </span>
                        <ReactTooltip
                          id={"Bid-volume-tooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                          className="sm-none"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Bid volume:</strong> The volume provided
                              at the current highest bid price for the Warrant.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th className="bgcolor-05" style={{ width: "5.1%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"Bid-myr-tooltip"}
                        >
                          Bid
                          <br className="xs-none" />
                          <span className="xs-none">(MYR)</span>
                        </span>
                        <ReactTooltip
                          id={"Bid-myr-tooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                          className="sm-none xs-none"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Bid:</strong> The highest current buy
                              price of the Warrant. This is the value that most
                              accurately reflects the 'value' of the Warrant at
                              any point in time.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th
                        className="bgcolor-05 xs-none"
                        style={{ width: "5.1%" }}
                      >
                        <span
                          className="ttip"
                          data-tip
                          data-for={"Ask-myr-tooltip"}
                        >
                          Ask
                          <br />
                          (MYR)
                        </span>
                        <ReactTooltip
                          id={"Ask-myr-tooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                          className="sm-none"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Ask:</strong> The lowest current sell
                              price for the Warrant.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th
                        className="bgcolor-05 xs-none"
                        style={{ width: "7.1%" }}
                      >
                        <span
                          className="ttip"
                          data-tip
                          data-for={"Ask-volume-tooltip"}
                        >
                          Ask volume
                          <br />
                          ('000)
                        </span>
                        <ReactTooltip
                          id={"Ask-volume-tooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                          className="sm-none"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Ask volume:</strong> The volume provided
                              at the current lowest sell price for the Warrant.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th className="bgcolor-05" style={{ width: "8.1%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"Traded-volume-tooltip"}
                        >
                          <span className="xs-none">Traded</span> volume
                          <br />
                          ('000)
                        </span>
                        <ReactTooltip
                          id={"Traded-volume-tooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                          className="sm-none xs-none"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Traded volume:</strong> Total volume
                              traded in the Warrant for the current day. A
                              Warrant that has not traded for the day does not
                              necessarily mean that it is not liquid.{" "}
                              <Link
                                target="_blank"
                                to="/edu/market-marker/liquidity-vs-volume"
                              >
                                {" "}
                                Click here{" "}
                              </Link>{" "}
                              for more info about Warrant and liquidity.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th className="bgcolor-05" style={{ width: "7%" }}>
                        <span
                          className="ttip"
                          data-tip
                          data-for={"Price-change-tooltip"}
                        >
                          Price change
                          <br />
                          (%)
                        </span>
                        <ReactTooltip
                          id={"Price-change-tooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                          className="sm-none xs-none"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Price change:</strong> Current bid price
                              for the Warrant compared to the Prior (previous
                              days close). The bid price of the Warrant 1 minute
                              before close of trading at 5.00pm, will be used
                              for the Prior. For more info
                              <Link
                                to="/edu/monitoring-price-changes/closing-times"
                                target="_blank"
                              >
                                {" "}
                                click here{" "}
                              </Link>{" "}
                              .
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      {/* DW */}
                      <th
                        className="bgcolor-06 sm-none xs-none"
                        style={{ width: "5.1%" }}
                      >
                        <span
                          className="ttip"
                          data-tip
                          data-for={"Delta-tooltip"}
                        >
                          Delta
                          <br />
                          (%)
                        </span>
                        <ReactTooltip
                          id={"Delta-tooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                          className="sm-none"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Delta:</strong> Estimates how a warrant
                              moves in relation to the underlying stock/index.
                              Puts have negative deltas because their prices
                              increase as the underlying stock/index falls.
                              Note, if your warrant has a WPS that this will
                              need to be taken into account.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th
                        className="bgcolor-06 xs-none"
                        style={{ width: "6.6%" }}
                      >
                        <span
                          className="ttip"
                          data-tip
                          data-for={"gearing-tooltip"}
                        >
                          Eff. gearing
                          <br />
                          (x)
                        </span>
                        <ReactTooltip
                          id={"gearing-tooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                          className="sm-none"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Effective gearing:</strong> Indicates your
                              leveraged exposure. An effective gearing of 5X
                              tells you a 1% move in the underlying stock/index
                              results in an approximate 5% move in your Warrant
                              price.{" "}
                              <Link
                                to="/edu/useful-warrant-terms/effective-gearing"
                                target="_blank"
                              >
                                {" "}
                                Click here{" "}
                              </Link>{" "}
                              to learn more about effective gearing.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th
                        className="bgcolor-06 sm-none xs-none"
                        style={{ width: "6.1%" }}
                      >
                        <span
                          className="ttip"
                          data-tip
                          data-for={"sensitivity-tooltip"}
                        >
                          Sensitivity
                        </span>
                        <ReactTooltip
                          id={"sensitivity-tooltip"}
                          delayHide={100}
                          place="left"
                          type="warning"
                          effect="solid"
                          clickable={true}
                          className="sm-none"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Sensitivity:</strong> Shows you how much
                              the underlying stock or index must move for your
                              warrant to move one "tick" or "Spread". For
                              warrants that cost less than RM1, one tick is
                              RM0.005.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <th
                        className="bgcolor-06 xs-none"
                        style={{ width: "8%" }}
                      >
                        <span
                          className="ttip"
                          data-tip
                          data-for={"Implied-volatility"}
                        >
                          Implied volatility
                          <br />
                          (%)
                        </span>
                        <ReactTooltip
                          id={"Implied-volatility"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                          className="sm-none"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Implied volatility:</strong> The higher
                              the implied volatility, the higher the price of
                              the Warrant (all other factors held constant).{" "}
                              <Link
                                to="/edu/useful-warrant-terms/implied-volatility"
                                target="_blank"
                              >
                                {" "}
                                Click here{" "}
                              </Link>{" "}
                              to learn more about implied volatility.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                    </tr>
                    <tr className="result-sort">
                      <th>
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "wcode")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "wcode")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="sm-none xs-none"></th>
                      <th className="sm-none xs-none">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "uprice")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "uprice")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="xs-none">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "type")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "type")}
                          className="result-sort-up"
                        />
                      </th>
                      <th>
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "ep")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "ep")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="xs-none">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "dwps")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "dwps")}
                          className="result-sort-up"
                        />
                      </th>
                      <th>
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "mdate")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "mdate")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="xs-none">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "isr")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "isr")}
                          className="result-sort-up"
                        />
                      </th>
                      {/* Market */}
                      <th className="xs-none">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "bv")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "bv")}
                          className="result-sort-up"
                        />
                      </th>
                      <th>
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "bp")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "bp")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="xs-none">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "ap")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "ap")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="xs-none">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "av")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "av")}
                          className="result-sort-up"
                        />
                      </th>
                      <th>
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "tv")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "tv")}
                          className="result-sort-up"
                        />
                      </th>
                      <th>
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "pc")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "pc")}
                          className="result-sort-up"
                        />
                      </th>
                      {/* DW */}
                      <th className="sm-none xs-none">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "dlt")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "dlt")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="xs-none">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "eg")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "eg")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="sm-none xs-none">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "se")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "se")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="xs-none">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "iv")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "iv")}
                          className="result-sort-up"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <JoinTelegramModal
          Url={url}
          Open={open}
          OpenModal={() => setOpen(!true)}
        />
      </div>
    </>
  );
};
export default WarrantSearch;
