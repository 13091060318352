import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import {
  getIndexWarrant,
  getAlert,
  getWarrantIssue,
} from "../../../utils/WarrantIssue.api";
import ChartWarrant from "./ChartWarrant";
import moment from "moment";
import { Link } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";

const DataModal = ({ open, setOpen, keyValue }) => {
  const [load, setLoad] = useState(false);
  const [call, setCall] = useState();
  const [put, setPut] = useState();
  const [result, setResult] = useState();
  const [alertResult, setAlertResult] = useState();
  const [loader, setLoader] = useState(false);
  const [currencyType, setCurrencyType] = useState("");
  const [staticData, setStaticData] = useState();
  const [viewClicked, setviewClicked] = useState(false);

  // const closePopup = () => {
  //   setOpen(false)
  // }

  const modalData = [
    {
      id: 1,
      headText: "Nikkei Stock Average (Nikkei 225) Index",
      profileImg: "/img/bg/japan_flag_svg.png",
      text: "The Nikkei Stock Average (Nikkei 225) Index stands as the ultimate yardstick for Japanese stocks on a global scale. With a rich history spanning over 70 years, this index has consistently served as the benchmark for tracking the movement of the Japanese stock markets. Crafted as a price-weighted equity index, it comprises a curated selection of 225 stocks from the Prime Market of the Tokyo Stock Exchange, solidifying its status as one of the global indicators of market movements. <br /><br />Macquarie’s warrants over the Nikkei Stock Average (Nikkei 225) Index track the SGX Nikkei 225 Index Futures contracts which expire on every second Friday of each quarter i.e. March, June, September and December. For example, in the month of August 2023, the Nikkei Stock Average (Nikkei 225) Index warrants will track the SGX Nikkei 225 Index Futures contracts expiring in September 2023.",
      img1: "/img/index/Nikkei_icon3.svg",
      img2: "/img/index/Nikkei_icon1.svg",
      img3: "/img/index/Nikkei_icon_2.svg",
      text1: "Indicator of Japanese stock market movements.",
      text2: "",
      text3: "Acts as the forerunner",
      text4:
        "to reflect early global stock market direction, especially on Mondays, as it trades earliest among other world major indices.",
      text5: "High liquidity",
      text6:
        "through actively traded futures, options and ETFs around the world.",
      tableHeading: "N225",
    },
    {
      id: 2,
      headText: "SPDR® Gold Trust",
      profileImg: "/img/bullet/sflag_hk.svg",
      text: "The SPDR® Gold Trust (“GLDHK”; stock code 2840 HK) is an exchange traded fund (ETF) with shares listed on the Stock Exchange of Hong Kong Limited (SEHK). It is designed to reflect the price performance of the gold bullion (less GLDHK’s expenses) and offers investors a way to participate in the gold bullion market without having to take physical delivery of gold, and to buy and sell that interest through an exchange. The value of the gold held by GLDHK is determined using the “LBMA Gold Price PM”, which is the afternoon fixing price of an ounce of gold based on an auction administered by the ICE Benchmark Administration Limited.",
      img1: "/img/index/2840HK_1.svg",
      img2: "/img/index/2840HK_z.svg",
      img3: "/img/index/2840HK_2.svg",
      text1: "One of the largest Gold ETFs",
      text2: "in the world that tracks the price of gold bullion, since its launch in November 2004",
      text3: "Easily accessible",
      text4: "The GLDHK is cross-listed in Hong Kong, offering price data availability on various public platforms and covering most of the Malaysian trading hours",
      text5: "Potential hedge against inflation",
      text6:"Gold has a track record of providing a hedge during periods of volatility and uncertainty. The GLDHK, providing a relatively cost-efficient way to access the gold market, grants investors access to diversification and a safe-haven asset.",
      tableHeading: "2840 HK",
    },
    {
      id: 3,
      headText: "Hang Seng Index",
      profileImg: "/img/bullet/sflag_hk.svg",
      text: "The Hang Seng Index (“HSI”) is the most widely quoted performance indicator of the Hong Kong share market, and one of the most watched indexes in the region. Comprising some of the largest shares on the Hong Kong exchange, it tracks the movement in the Hong Kong market and is very closely linked to the Chinese economy. Many investors across the world use the HSI as a proxy to invest into China.",
      img1: "/img/index/HSI1.svg",
      img2: "/img/index/HSI2.svg",
      img3: "/img/index/HSI3.svg",
      text1: "Comprises some of the largest",
      text2: "and most liquid stocks listed on the HKEX",
      text3: "Captures major sectors",
      text4:
        "with constituents grouped into Finance, Utilities, Properties, and Commerce and Industry",
      text5: "Tracks the HK market",
      text6: "and is closely linked to the Chinese economy",
      tableHeading: "HSI",
    },
    {
      id: 4,
      headText: "Hang Seng TECH Index",
      profileImg: "/img/bullet/sflag_hk.svg",
      text: `The Hang Seng TECH Index (“HSTECH”) comprises the largest companies listed on the Hong Kong Stock Exchange which have high exposure in selected technology themes. First launched in July 2020, the more notable constituents include well-known names such as Alibaba, Tencent, Meituan and Xiaomi (commonly referred to as ‘ATMX’) which have a combined weight of more than 30%. Investors will be able to gain exposure to these heavyweights as well as a diverse spectrum of themes within the tech sector including internet, fintech, cloud, e-commerce and digital while minimizing any single stock specific risk.<br/><br/>View more information on the HSTECH <a target=${"_blank"} href=${"/warrant-education/HKunderlyings"}>here</a>.<br/></br><i>Note: The chart below includes back-tested data compiled by the Hang Seng Indexes Company Limited for the period before the HSTECH’s launch date of 27 July 2020.</i>`,
      img1: "/img/index/HSTECH1.svg",
      img2: "/img/index/HSTECH2.svg",
      img3: "	/img/index/HSTECH3.svg",
      text1: "Higher volatility",
      text2: "than the HSI on average",
      text3: "Ease of access",
      text4: "Trade warrants on Bursa using your existing trading account",
      text5: "Broad coverage",
      text6: "of the technology sector, including the popular ATMX!",
      tableHeading: "HSTECH",
    },
    {
      id: 5,
      headText: "FTSE Bursa Malaysia KLCI",
      profileImg: "/img/bullet/sflag_my.svg",
      text: "The FTSE Bursa Malaysia KLCI (“FBMKLCI”) is comprised of the top 30 companies by market capitalization on the Bursa Malaysia Main Market, including Maybank, Tenaga Nasional, Public Bank, Sime Darby and other household names. It is the headline index for the Bursa and is the most widely used benchmark to track the performance of the Malaysian market.</br></br>The FBM KLCI futures (“FKLI”) are listed and traded on the Bursa Malaysia, the FBMKLCI warrant price is linked to the movements in the futures price over time and at expiry settlement will be based on the closing level of the relevant futures expiry.",
      img1: "/img/index/Bursa1.svg",
      img2: "/img/index/Bursa2.svg",
      img3: "/img/index/Bursa3.svg",
      text1: "Comprises 30",
      text2: "of the largest companies on Bursa Malaysia",
      text3: "Benchmark",
      text4: "to track the performance of Malaysia’s stock market",
      text5: "Household names",
      text6: "such as Maybank, Tenaga Nasional and Sime Darby",
      tableHeading: "FBMKLCI",
    },
    {
      id: 6,
      headText: "iShares FTSE China A50 ETF",
      profileImg: "/img/bullet/sflag_cn.svg",
      text: "The iShares FTSE China A50 ETF (“China A50 ETF”) is an exchange traded fund listed on the Stock Exchange of Hong Kong (SEHK), whose movement closely corresponds to the performance of the FTSE China A50 Index. The index is designed to represent the performance of the mainland Chinese market through the 50 largest A Share companies listed on the Shanghai and Shenzhen stock exchanges.",
      img1: "/img/index/China1.svg",
      img2: "/img/index/China2v2.png",
      img3: "/img/index/China3.svg",
      text1: "Net assets",
      text2: "of over RMB15 billion",
      text3: "FTSE China A50 Index",
      text4: "comprises the top 50 A-Share companies by market capitalization",
      text5: "Provides access",
      text6: "to movements in the China A-Share market",
      tableHeading: "China A50 ETF",
    },
    {
      id: 7,
      headText: "The Standard & Poor's 500 index",
      profileImg: "/img/bullet/sflag_us.svg",
      text: "The Standard & Poor's 500® Index (SP500) is widely regarded as the best single gauge of large-cap U.S. equities and is one of the most followed equity indices in the world. There is over USD $16.8 trillion benchmarked to the SP500 (as of 31 December 2022) and it captures approximately 80% of the U.S. market capitalization. The index tracks 500 of the largest companies listed on the New York Stock Exchange and the NASDAQ including, Apple Inc, Amazon, Johnson and Johnson, Microsoft Corp, Facebook Inc, etc.",
      img1: "/img/index/S&P1.svg",
      img2: "/img/index/S&P2.svg",
      img3: "/img/index/S&P3.svg",
      text1: "Trades 23 hours",
      text2:
        "Trading in S&P 500® futures covers the full trading hours of Bursa Malaysia",
      text3: "Over $16.8 trillion",
      text4: "in assets benchmarked to the index",
      text5: "Captures ≈ 80%",
      text6: "of U.S. equity market capitalization",
      tableHeading: "S&P 500",
    },
    {
      id: 8,
      headText: "The NASDAQ-100 Index",
      profileImg: "/img/bullet/sflag_us.svg",
      text: "The NASDAQ-100® Index (“NDX”) is one of the world’s distinguished large-cap growth indexes and is home to some of the world’s most innovative companies including Apple, Google, Intel and Tesla. The index includes 100 of the largest U.S. and international non-financial companies listed on the NASDAQ Stock Market based on market capitalization. The index reflects companies across major industry groups including computer hardware and software, telecommunications, retail/wholesale trade and biotechnology, and does not contain securities of financial or investment companies.",
      img1: "/img/index/NASDAQ1.svg",
      img2: "/img/index/NASDAQ2.svg",
      img3: "/img/index/NASDAQ3.svg",
      text1: "100 of the largest",
      text2:
        "U.S. and international non-financial companies based on market capitalization",
      text3: "Houses innovative companies",
      text4: "such as Apple, Google and Tesla",
      text5: "Heavy allocation",
      text6:
        "to top performing industries like Technology and Consumer Services",
      tableHeading: "NDX",
    },
  ];

  useEffect(() => {
    setStaticData(
      keyValue === 1
        ? modalData[0]
        : keyValue === 2
        ? modalData[1]
        : keyValue === 3
        ? modalData[2]
        : keyValue === 4
        ? modalData[3]
        : keyValue === 5
        ? modalData[4]
        : keyValue === 6
        ? modalData[5]
        : keyValue === 7
        ? modalData[6]
        : keyValue === 8
        ? modalData[7]
        : modalData[7]
    );
  }, []);
  useEffect(() => {
    getIndexData();
    getAlertData();
    getWarrantData();
  }, []);

  function getIndexData() {
    setLoad(true);
    var ticker =
      keyValue === 1
        ? "N225"
        : keyValue === 2
        ? '2840.HK'
        : keyValue === 3
        ? "HSI"
        : keyValue === 4
        ? "HSTECH"
        : keyValue === 5
        ? "KLSE"
        : keyValue === 6
        ? "2823"
        : keyValue === 7
        ? "S%26P500"
        : keyValue === 8
        ? "NDX"
        : "N225";
    var qid = Date.now();
    getIndexWarrant(ticker, qid).then((res) => {
      setCall(res.data.CALL);
      setPut(res.data.PUT);
      // var currType = res.data.CALL && res.data.CALL[0] && res.data.CALL[0].underlying_ric

      var currType = ticker;
      if (currType == "S%26P500") {
        setCurrencyType("USD");
      } else if (currType == "DJIA") {
        setCurrencyType("USD");
      } else if (currType == "NDX") {
        setCurrencyType("USD");
      } else if (currType == "KLSE") {
        setCurrencyType("MYR");
      } else if (currType == "SSIcv1") {
        setCurrencyType("JPY");
      } else {
        setCurrencyType("HKD");
      }
      setLoad(false);
    });
  }

  function getAlertData() {
    var ric =
      keyValue === 1
        ? "SSIcv1"
        : keyValue === 2
        ? "2840.HK"
        : keyValue === 3
        ? "HSIc1"
        : keyValue === 4
        ? "HHTIc1"
        : keyValue === 5
        ? "FKLIc1"
        : keyValue === 6
        ? "2823.HK"
        : keyValue === 7
        ? "ESc1"
        : keyValue === 8
        ? "NQc1"
        : "NQc1";
    var qid = Date.now();
    getAlert(ric, qid).then((res) => {
      setAlertResult(res.data);
    });
  }

  function getWarrantData() {
    setLoader(true);
    var qid = Date.now();
    getWarrantIssue(qid).then((res) => {
      var warrantIssue = res.data.ranking;
      var newData = warrantIssue.filter((d) => d.underlying_ticker === "HSI");
      var newData1 = warrantIssue.filter((d) => d.underlying_ticker === "DJIA");
      var newData2 = warrantIssue.filter(
        (d) => d.underlying_ticker === "HSTECH"
      );
      var newData3 = warrantIssue.filter((d) => d.underlying_ticker === "KLSE");
      var newData4 = warrantIssue.filter((d) => d.underlying_ticker === "2823");
      var newData5 = warrantIssue.filter(
        (d) => d.underlying_ticker === "S&P500"
      );
      var newData6 = warrantIssue.filter((d) => d.underlying_ticker === "NDX");
      var newData7 = warrantIssue.filter((d) => d.underlying_ticker === "N225");
      var newData8 = warrantIssue.filter((d) => d.underlying_ticker === "2840");
      setResult(
        keyValue === 1
          ? newData7
          : keyValue === 2
          ? newData8
          : keyValue === 3
          ? newData
          : keyValue === 4
          ? newData2
          : keyValue === 5
          ? newData3
          : keyValue === 6
          ? newData4
          : keyValue === 7
          ? newData5
          : keyValue === 8
          ? newData6
          : newData7
      );
      setLoader(false);
    });
  }

  return (
    <>
      <Modal centered isOpen={open} className="slider-modal datamodal_sec">
        <ModalBody>
          <OutsideClickHandler>
            <div className="overlay-inner">
              <div style={{ position: "relative" }}>
                <img
                  className="btn-close"
                  style={{}}
                  src="/img/bullet/icon-close-black.png"
                  onClick={() => setOpen(false)}
                />
                <div className="popupbox-content">
                  <div className="ppc" key="What-is-NDX">
                    <div className="row">
                      <div
                        className="col-sm-12"
                        style={{ display: "flex", alignItems: "flex-start" }}
                      >
                        <img
                          src={staticData?.profileImg}
                          style={{
                            width: "60px",
                            paddingRight: "10px",
                            margin: "0px",
                          }}
                        />
                        <h2 className="ppc-h">{staticData?.headText}</h2>
                        <h2></h2>
                      </div>
                      <div className="col-sm-12">
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              staticData != undefined ? staticData.text : "",
                          }}
                        ></p>
                      </div>
                      <div className="col-sm-12">
                        <div className="row rating-row">
                          <div className="col-md-4">
                            <div className="rating-item">
                              <div className="rating-img">
                                <img
                                  style={{ marginTop: "15px !important" }}
                                  src={staticData?.img1}
                                />
                              </div>
                              <div className="rating-item-txtarea">
                                <h4 className="rating-item-ttl">
                                  {staticData?.text1}
                                </h4>
                                <p className="rating-item-txt">
                                  {staticData?.text2}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="rating-item">
                              <div className="rating-img">
                                <img src={staticData?.img2} />
                              </div>
                              <div className="rating-item-txtarea">
                                <h4 className="rating-item-ttl">
                                  {staticData?.text3}
                                </h4>
                                <p className="rating-item-txt">
                                  {staticData?.text4}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="rating-item">
                              <div className="rating-img">
                                <img
                                  style={{ width: "43px !important" }}
                                  src={staticData?.img3}
                                />
                              </div>
                              <div className="rating-item-txtarea">
                                <h4 className="rating-item-ttl">
                                  {staticData?.text5}
                                </h4>
                                <p className="rating-item-txt">
                                  {staticData?.text6}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="call_table col-xs-12">
                        <div className="tw_table row">
                          <div
                            className="call_td_0 call_td col-md-3"
                            style={
                              call === undefined ||
                              call.length === 0 ||
                              call == ""
                                ? { display: "none" }
                                : {}
                            }
                          >
                            <div className="tw_warrant">
                              <div colSpan={2} className="tw_title">
                                <div className="tw_type">Call</div>
                                <div className="tw_ticker">
                                  {call?.map((d) => d.dsply_nmll)[0]}
                                </div>
                              </div>
                              <table style={{ width: "100%" }}>
                                <tbody>
                                  <tr>
                                    <td style={{ width: "40%" }}>Price:</td>
                                    <td style={{ width: "60%" }}>
                                      RM{" "}
                                      <span className="tw_BID">
                                        {call?.map((d) => d.BID.toFixed(3))[0]}
                                      </span>{" "}
                                      <br />(
                                      <span
                                        className={
                                          call &&
                                          call[0] &&
                                          call[0].BID_PCTCHNG &&
                                          call[0].BID_PCTCHNG.toString() <= 0
                                            ? "tw_BID_PCTCHNG down"
                                            : "tw_BID_PCTCHNG up"
                                        }
                                      >
                                        {call && call[0] && call[0].BID_PCTCHNG
                                          ? `${parseFloat(
                                              call[0].BID_PCTCHNG
                                            ).toFixed(1)}%`
                                          : "-"}
                                      </span>
                                      )
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ width: "40%" }}>Strike:</td>
                                    <td
                                      className="tw_strike"
                                      style={{ width: "60%" }}
                                    >
                                      {call?.map((d) => d.exercise_price)[0]}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ width: "40%" }}>Expiry:</td>
                                    <td
                                      className="tw_expiry"
                                      style={{ width: "60%" }}
                                    >
                                      {moment(
                                        call?.map((d) => d.maturity)[0]
                                      ).format("DD MMM YY")}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ width: "40%" }}>
                                      Eff gearing:
                                    </td>
                                    <td
                                      className="tw_eff"
                                      style={{ width: "60%" }}
                                    >
                                      {call &&
                                      call[0] &&
                                      call[0].effective_gearing
                                        ? parseFloat(
                                            call[0].effective_gearing
                                          ).toFixed(1)
                                        : "-"}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div
                            className="put_td_0 put_td col-md-3"
                            style={
                              put === undefined || put.length === 0 || put == ""
                                ? { display: "none" }
                                : {}
                            }
                          >
                            <div className="tw_warrant">
                              <div colSpan={2} className="tw_title">
                                <div className="tw_type">Put</div>
                                <div className="tw_ticker">
                                  {put?.map((d) => d.dsply_nmll)[0]}
                                </div>
                              </div>
                              <table style={{ width: "100%" }}>
                                <tbody>
                                  <tr>
                                    <td style={{ width: "40%" }}>Price:</td>
                                    <td style={{ width: "60%" }}>
                                      RM{" "}
                                      <span className="tw_BID">
                                        {put?.map((d) => d.BID.toFixed(3))[0]}
                                      </span>{" "}
                                      <br />(
                                      <span
                                        className={
                                          put &&
                                          put[0] &&
                                          put[0].BID_PCTCHNG &&
                                          put[0].BID_PCTCHNG.toString() <= 0
                                            ? "tw_BID_PCTCHNG down"
                                            : "tw_BID_PCTCHNG up"
                                        }
                                      >
                                        {put && put[0] && put[0].BID_PCTCHNG
                                          ? parseFloat(
                                              put[0].BID_PCTCHNG
                                            ).toFixed(1) + "%"
                                          : "-"}
                                      </span>
                                      )
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ width: "40%" }}>Strike:</td>
                                    <td
                                      className="tw_strike"
                                      style={{ width: "60%" }}
                                    >
                                      {put?.map((d) => d.exercise_price)[0]}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ width: "40%" }}>Expiry:</td>
                                    <td
                                      className="tw_expiry"
                                      style={{ width: "60%" }}
                                    >
                                      {moment(
                                        put?.map((d) => d.maturity)[0]
                                      ).format("DD MMM YY")}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ width: "40%" }}>
                                      Eff gearing:
                                    </td>
                                    <td
                                      className="tw_eff"
                                      style={{ width: "60%" }}
                                    >
                                      {put && put[0] && put[0].effective_gearing
                                        ? parseFloat(
                                            put[0].effective_gearing
                                          ).toFixed(1)
                                        : "-"}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div
                            className="call_td_1 call_td col-md-3"
                            style={
                              call?.length === 1 ||
                              call === undefined ||
                              call == ""
                                ? { display: "none" }
                                : {}
                            }
                          >
                            <div className="tw_warrant">
                              <div colSpan={2} className="tw_title">
                                <div className="tw_type">Call</div>
                                <div className="tw_ticker">
                                  {call?.map((d) => d.dsply_nmll)[1]}
                                </div>
                              </div>
                              <table style={{ width: "100%" }}>
                                <tbody>
                                  <tr>
                                    <td style={{ width: "40%" }}>Price:</td>
                                    <td style={{ width: "60%" }}>
                                      RM{" "}
                                      <span className="tw_BID">
                                        {call?.map((d) => d.BID.toFixed(3))[1]}
                                      </span>{" "}
                                      <br />(
                                      <span
                                        className={
                                          call &&
                                          call[1] &&
                                          call[1]?.BID_PCTCHNG &&
                                          call[1]?.BID_PCTCHNG.toString() <= 0
                                            ? "tw_BID_PCTCHNG down"
                                            : "tw_BID_PCTCHNG up"
                                        }
                                      >
                                        {call && call[1] && call[1]?.BID_PCTCHNG
                                          ? `${parseFloat(
                                              call[1]?.BID_PCTCHNG
                                            ).toFixed(1)}%`
                                          : "-"}
                                      </span>
                                      )
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ width: "40%" }}>Strike:</td>
                                    <td
                                      className="tw_strike"
                                      style={{ width: "60%" }}
                                    >
                                      {call?.map((d) => d.exercise_price)[1]}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ width: "40%" }}>Expiry:</td>
                                    <td
                                      className="tw_expiry"
                                      style={{ width: "60%" }}
                                    >
                                      {moment(
                                        call?.map((d) => d.maturity)[1]
                                      ).format("DD MMM YY")}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ width: "40%" }}>
                                      Eff gearing:
                                    </td>
                                    <td
                                      className="tw_eff"
                                      style={{ width: "60%" }}
                                    >
                                      {call &&
                                      call[1] &&
                                      call[1].effective_gearing
                                        ? parseFloat(
                                            call[1].effective_gearing
                                          ).toFixed(1)
                                        : "-"}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div
                            className="put_td_1 put_td col-md-3"
                            style={
                              put?.length === 1 ||
                              put === undefined ||
                              put == ""
                                ? { display: "none" }
                                : {}
                            }
                          >
                            <div className="tw_warrant">
                              <div colSpan={2} className="tw_title">
                                <div className="tw_type">Put fgh</div>
                                <div className="tw_ticker">
                                  {put?.map((d) => d.dsply_nmll)[1]}
                                </div>
                              </div>
                              <table style={{ width: "100%" }}>
                                <tbody>
                                  <tr>
                                    <td style={{ width: "40%" }}>Price:</td>
                                    <td style={{ width: "60%" }}>
                                      RM{" "}
                                      <span className="tw_BID">
                                        {put?.map((d) => d.BID.toFixed(3))[1]}
                                      </span>{" "}
                                      <br />(
                                      <span
                                        className={
                                          put &&
                                          put[1] &&
                                          put[1].BID_PCTCHNG &&
                                          put[1].BID_PCTCHNG.toString() <= 0
                                            ? "tw_BID_PCTCHNG down"
                                            : "tw_BID_PCTCHNG up"
                                        }
                                      >
                                        {put && put[1] && put[1].BID_PCTCHNG
                                          ? parseFloat(
                                              put[1].BID_PCTCHNG
                                            ).toFixed(1) + "%"
                                          : "-"}
                                      </span>
                                      )
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ width: "40%" }}>Strike:</td>
                                    <td
                                      className="tw_strike"
                                      style={{ width: "60%" }}
                                    >
                                      {put?.map((d) => d.exercise_price)[1]}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ width: "40%" }}>Expiry:</td>
                                    <td
                                      className="tw_expiry"
                                      style={{ width: "60%" }}
                                    >
                                      {moment(
                                        put?.map((d) => d.maturity)[1]
                                      ).format("DD MMM YY")}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ width: "40%" }}>
                                      Eff gearing:
                                    </td>
                                    <td
                                      className="tw_eff"
                                      style={{ width: "60%" }}
                                    >
                                      {put && put[1] && put[1].effective_gearing
                                        ? parseFloat(
                                            put[1].effective_gearing
                                          ).toFixed(1)
                                        : "-"}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Link
                        className={`viewLM ${result && result[0]?.ric}`}
                        target="_blank"
                        to={`/tools/livematrix/${
                          call && call[0] ? call[0].ticker : ""
                        }${call && call[1] ? "&" + call[1].ticker : ""}${
                          put && put[0] ? "&" + put[0].ticker : ""
                        }${put && put[1] ? "&" + put[1].ticker : ""}`}
                      >
                        <div
                          className="vlmbtn bgcolor-06 btn"
                          style={{ backgroundColor: "#fecc0a" }}
                        >
                          View matrix{" "}
                          {loader && <Spinner size="sm" variant="light" />}
                        </div>
                      </Link>
                      {
                        keyValue==3&&(
                          <div className="col-sm-12" style={{padding:'20px'}}>
                            <div className="row" >
                              <div className="col-md-6  col-sm-12  hstiImg" sytle={{minHeight: '300px'}}>
                                <h2 style={{color:'#004070',fontWeight:'bold',padding:'35px'}}>Why trade Macquarie's Hang Seng Index warrants?</h2>
                              </div>
                              <div className="col-md-6 col-sm-12 hstiBox" style={{padding:'0px'}}>
                                <div style={{background:'#218CD9',height:'100%',color:'#fff',padding: '20px 40px 20px 55px'}}>
                                    <h6> <img src="/img/bullet/Point_tick.png" alt="" style={{width:'20px',height:'20px',marginLeft: '-27px',marginRight: '5px',verticalAlign: 'bottom'}}/>High liquidity</h6>
                                    <p>Macquarie provides high liquidity for Hang Seng Index warrants, with volumes of up to 12 million units placed on each of the bid and offer price for our focus warrants, allowing investors to enter and exit their positions with relative ease.</p>
                                    <h6> <img src="/img/bullet/Point_tick.png" alt="" style={{width:'20px',height:'20px',marginLeft: '-27px',marginRight: '5px',verticalAlign: 'bottom'}}/>Wide selection</h6>
                                    <p>Macquarie offers a wide range of Hang Seng Index warrants with various strikes and tenures, catering to investors with different risk appetites and trading time horizons.</p>
                                    <h6>  <img src="/img/bullet/Point_tick.png" alt="" style={{width:'20px',height:'20px',marginLeft: '-27px',marginRight: '5px',verticalAlign: 'bottom'}}/>Live Matrix Plus</h6>
                                    <p>The Live Matrix for all Macquarie warrants is easily accessible for free on our website, allowing investors to check where our bid and offer prices would be in a warrant. You can also compare key warrant terms with this tool.</p>
                                    <h6>  <img src="/img/bullet/Point_tick.png" alt="" style={{width:'20px',height:'20px',marginLeft: '-27px',marginRight: '5px',verticalAlign: 'bottom'}}/>Constant support and education</h6>
                                    <p>Access the latest updates on warrants via our website and social channels. Contact us anytime during trading hours for assistance.</p>
                                    <div>
                                      <a href="/marketnews/subscribe">
                                      <img  src="/img/bullet/Subscribe_newsletter.png" style={{width:'30px',cursor:'pointer',height:'30px',marginRight:'5px'}}/>
                                      </a>
                                    <a href="tel:+60 3 2059 8840" style={{position:'relative'}} id="imgHover">
                                    <img  src="/img/bullet/Contact_us.png"   style={{width:'30px',cursor:'pointer',height:'30px',marginRight:'5px'}}/>
                                    <div
                                        style={{ display: "none", top: '38px', left: "-177px" }}
                                        className="dropmenu-content nav-icon-tooltip"
                                      >
                                        <div className="icon-tooltip-bg" />
                                        <div className="icon-tooltip-content">
                                          <i>
                                            <a href="tel:+60 3 2059 8840">+60 3 2059 8840</a>
                                            <br />
                                            <a href="mailto:info@malaysiawarrants.com.my">
                                              info@malaysiawarrants.com.my
                                            </a>
                                          </i>
                                        </div>
                                      </div>
                                    </a>
                                    
                                    
                                    <a href="/telegram" target="_black">
                                      <img src="/img/bullet/t_logo.60d0f86544088e323110.png" alt="" style={{width:'30px',height:'30px',marginRight:'5px'}}/>
                                    </a>
                                    <a href="https://www.facebook.com/MalaysiaWarrants/"  target="_black">
                                      <img src="/img/bullet/download__1_.png" alt="" style={{width:'30px',height:'30px'}}/>
                                    </a>
                                    
                                    </div>
                                    <p style={{margin:'10px 0px 5px 0px '}}>Educational resources available:</p>
                                    <div className="hsti_a_Box">
                                      <a  style={{padding:'5px 15px 5px 15px',background:'#0066AD',borderRadius:'10px',marginRight:'10px',maxWidth: '160px',textAlign: 'center'}} href="/education/seminar">Seminars</a>
                                      <a style={{padding:'5px 15px 5px 15px',background:'#0066AD',borderRadius:'10px',marginRight:'10px',maxWidth: '160px',textAlign: 'center'}}  href="/edu">Investor academy</a>
                                      <a style={{padding:'5px 15px 5px 15px',background:'#0066AD',borderRadius:'10px',maxWidth: '160px',textAlign: 'center'}}  href='/education/warrantvideos'>Educational videos</a>
                                    </div>
                                    <h6  style={{margin:'10px 0px 5px 0px '}}>  <img src="/img/bullet/Point_tick.png" alt="" style={{width:'20px',height:'20px',marginLeft: '-27px',marginRight: '5px',verticalAlign: 'bottom'}}/>Asia’s leading warrant issuer</h6>
                                    <p>Macquarie is the leading warrant issuer in Asia, and the only issuer active in 4 warrant markets in the region.</p>
                                    <div>
                                      <a style={{padding:'5px 15px 5px 15px',background:'#0066AD',borderRadius:'10px',marginRight:'10px'}} href="/education/whymacquarie">Learn more</a>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                      
                      <div className="col-sm-12">
                        <div
                          id="chartbox"
                          style={{
                            width: "100%",
                            overflow: "hidden",
                            textAlign: "left",
                          }}
                        >
                          <div style={{ position: "relative" }}>
                            <div
                              style={{
                                overflow: "hidden",
                                position: "relative",
                                textAlign: "left",
                                cursor: "default",
                                width: "100%",
                              }}
                            >
                              {alertResult && currencyType && (
                                <ChartWarrant
                                  period="D"
                                  dataArr={alertResult}
                                  marketRic={currencyType}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <Link
                        className={`viewC col-xs-12 ${
                          result?.map((d) => d.underlying_ric)[0]
                        }`}
                        target="_blank"
                        to={`/tools/underlying/${
                          result?.map((d) => d.underlying_ric)[0]
                        }`}
                      >
                        <div className="vcbtn bgcolor-06 btn">
                          View charts{" "}
                          {loader && <Spinner size="sm" variant="light" />}
                        </div>
                      </Link>
                      <div className="col-sm-12">
                        <h2 className="table-heading">
                          {staticData?.tableHeading} warrants issued by
                          Macquarie
                        </h2>
                        <table
                          id="warranttbl"
                          className="table table-striped table-closed table-responsive"
                        >
                          <thead>
                            <tr>
                              <th>Warrant Name</th>
                              <th>Underlying</th>
                              <th>Type</th>
                              <th className="hidden-xs">Last bid price</th>
                              <th className="hidden-xs">Change</th>
                              <th className="hidden-xs">Change (%)</th>
                              <th className="hidden-xs">Volume ('000)</th>
                              <th>Expiry</th>
                              <th className="hidden-xs">Last trading date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {result &&
                              result.map((d) => (
                                <tr className="rowtmpl" idx={0}>
                                  <td className="col_dsply_nmll">
                                    <a
                                      target="_blank"
                                      href={`/tools/livematrix/${d.ticker}`}
                                    >
                                      {d.dsply_nmll}
                                    </a>
                                  </td>
                                  <td className="col_underlying_ticker">
                                    <a
                                      target="_blank"
                                      href={`/tools/underlying/${d.underlying_ric}`}
                                    >
                                      {d.underlying_ticker}
                                    </a>
                                  </td>
                                  <td className="col_type">{d.type}</td>
                                  <td className="hidden-xs col_BID">{d.BID}</td>
                                  <td
                                    className={
                                      d.BID_NETCHNG.includes("-")
                                        ? "hidden-xs col_BID_NETCHNG down"
                                        : d.BID_NETCHNG.includes("+")
                                        ? "hidden-xs col_BID_NETCHNG up"
                                        : "hidden-xs col_BID_NETCHNG"
                                    }
                                  >
                                    {d.BID_NETCHNG}
                                  </td>
                                  <td
                                    className={
                                      d.BID_PCTCHNG.includes("-")
                                        ? "hidden-xs col_BID_PCTCHNG down"
                                        : d.BID_PCTCHNG.includes("+")
                                        ? "hidden-xs col_BID_PCTCHNG up"
                                        : "hidden-xs col_BID_PCTCHNG"
                                    }
                                  >
                                    {d.BID_PCTCHNG}
                                  </td>
                                  <td className="hidden-xs col_ACVOL_1_SCALED">
                                    {d.ACVOL_1_SCALED}
                                  </td>
                                  <td className="col_maturity">{d.maturity}</td>
                                  <td className="hidden-xs col_last_trading_date">
                                    {d.last_trading_date}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </OutsideClickHandler>
        </ModalBody>
      </Modal>
    </>
  );
};
export default DataModal;
