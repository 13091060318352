import React from 'react';
import { Link } from 'react-router-dom';



const Loser = ({TopLosersList}) => {
  
  // const strConvert =(a)=>{
  //   let tempNum =  a.toString();
  //   const tempStr = tempNum.slice(0,tempNum.length-4)
  //   return tempStr;
  // }


  const setClass = (val) => {
    let className = " col PCTCHNG "
    if ((val).toString().includes("+")) {
  
      className += "up"
    }
    else if ((val).toString().includes("-")) {
      className += "down"
    }
    return className;
  }

  
    return ( 
        <div id="glTbl">
                <table className="table table-striped table-closed">
                  <thead>
                    <tr>
                      <th>Warrant name</th>
                      <th className='xs-none'>Underlying</th>
                      <th className='xs-none'>Issuer</th>
                      <th>Type</th>
                      <th>Bid<br />(MYR)</th>
                      <th>Change<br />(%)</th>
                      <th>Volume<br />('000)</th>
                      <th>Expiry</th>
                    </tr>
                  </thead>
                  <tbody>
                    {TopLosersList && TopLosersList.map((data,idx)=>(
                    <tr className="rowtmpl_gl" style={{}} key={idx} idx={idx}>
                      <td className="col ticker"><Link to={`${"/tools/livematrix/"}${data.ticker}`}>{data.dsply_nmll}</Link></td>
                      <td className="col underlying_name xs-none">{data.underlying_name}</td>
                      <td className="col issuer_code xs-none">{data.issuer_code}</td>
                      <td className="col type">{data.type}</td>
                      <td className="col BID">{data.BID}</td>
                      <td
                      //  className="col PCTCHNG down"
                      className={
                        
                        setClass(data && data.BID_PCTCHNG)
                      }
                      >{data.BID_PCTCHNG}</td>
                      <td className="col ACVOL_1">
                        {data.ACVOL_1_SCALED}
                        </td>
                      <td className="col days_to_maturity">{data.days_to_maturity}</td>
                    </tr>
                    ))}
                    </tbody>
                </table>
              </div>
     );
}
 
export default Loser;