import React from "react";
import ReactTooltip from "react-tooltip";
import {Link} from "react-router-dom"

function QuickTerms({ resultName, index, myRef, activeTab,cdata }) {
    function Change_bid_price(){
        if(resultName[0].BID_PCTCHNG === "0.0" || resultName[0].BID_PCTCHNG === "-"){
            resultName[0].BID_PCTCHNG = "N/A";
        } else {
            Number(resultName[0].BID_PCTCHNG)
        }
    }

    Change_bid_price()
    const getUnderlyingTickerDisplayName = (val, name, type) => {
        let UnderlyingtickerdisplaynameMap = JSON.parse(window.localStorage.getItem('UnderlyingtickerdisplaynameMap'))
        let value = UnderlyingtickerdisplaynameMap.filter((v) => {
            return v.underlying_ric === val
        })
        if (value.length > 0) {
            if (type === 1) {
                return value[0].underlying_name
            } else {
                return value[0].underlying_ticker
            }
        } else {
            return name
        }
    }

    function changeTermSheetLink(d) {
        let ticker = d?.ticker
        window.open(`/apimqmy/pdf?type=termsheet&id=${ticker}`, "_blank")
    }

    return (
        <>
            {resultName.length > 0 && resultName?.map((d, keyData) =>
                <div
                    className="qtview"
                    ref={activeTab === "quickTerms" && myRef}
                    key={"101" + keyData}
                >
                    <div id="indtable">
                        <table className="table table-closed table-striped">
                            <tbody>
                            <tr>
                                <th
                                    className="ttip ttip0"
                                    data-target="bid"
                                    data-hasqtip={191}
                                >
                                        <span data-tip
                                              data-for={"tooltipdata_1"}>Warrant bid price</span>
                                </th>
                                <td className="val BID">{d.BID}</td>
                            </tr>
                            <ReactTooltip
                                id={"tooltipdata_1"}
                                delayHide={200}
                                place="right"
                                type="warning"
                                effect="solid"
                                clickable="true"
                            >
                                <div className="bg-tooltip">
                                    <p className="tooltip-content">
                                        <strong>Bid:</strong> The highest current buy price of the Warrant. This is the
                                        value that most accurately reflects the 'value' of the Warrant at any point in
                                        time.

                                    </p>
                                </div>
                            </ReactTooltip>
                            <tr>
                                <th
                                    className="ttip ttip1"
                                    data-target="pricechange"
                                    data-hasqtip={200}
                                >
                                        <span data-tip
                                              data-for={"tooltipdata_2"}>Change in bid price (%)</span>
                                </th>
                                <td>
                                        <span
                                            className={d.BID_PCTCHNG.includes("-") ? "val BID_PCTCHNG down" : d.BID_PCTCHNG.includes("+") ? "val BID_PCTCHNG up" : "val BID_PCTCHNG"}>
                                            {d.BID_PCTCHNG}{d.BID_PCTCHNG === "N/A" ? null : "%"}
                                        </span>
                                </td>
                            </tr>
                            <ReactTooltip
                                id={"tooltipdata_2"}
                                delayHide={200}
                                place="right"
                                type="warning"
                                effect="solid"
                                clickable="true"
                            >
                                <div className="bg-tooltip">
                                    <p className="tooltip-content">
                                        <strong>Price change:</strong> Current bid price for the Warrant compared to the
                                        Prior (previous days close). The bid price of the Warrant 1 minute before close
                                        of trading at 5.00pm, will be used for the Prior. For more info <Link to="#">click
                                        here</Link>.

                                    </p>
                                </div>
                            </ReactTooltip>
                            <tr>
                                <th
                                    className="ttip ttip2"
                                    data-target="tradedvolume"
                                    data-hasqtip={209}
                                >
                                        <span data-tip
                                              data-for={"tooltipdata_3"}>Traded volume ('000)</span>
                                </th>
                                <td>
                                        <span className="val ACVOL_1_SCALED">
                                            {d.ACVOL_1_SCALED}
                                        </span>
                                </td>
                            </tr>
                            <ReactTooltip
                                id={"tooltipdata_3"}
                                delayHide={200}
                                place="right"
                                type="warning"
                                effect="solid"
                                clickable="true"
                            >
                                <div className="bg-tooltip">
                                    <p className="tooltip-content">
                                        <strong>Traded volume:</strong> Total volume traded in the Warrant for the
                                        current day. A Warrant that has not traded for the day does not necessarily mean
                                        that it is not liquid. <Link to="/edu/market-marker/liquidity-vs-volume">Click
                                        here</Link> for more info about Warrant and liquidity.

                                    </p>
                                </div>
                            </ReactTooltip>
                            <tr>
                                <th
                                    className="ttip ttip3"
                                    data-target="lasttradeprice"
                                    data-hasqtip={218}
                                >
                                        <span data-tip
                                              data-for={"tooltipdata_4"}>Last trade price</span>
                                </th>
                                <td>
                                    <span className="val TRDPRC_1">{d.TRDPRC_1}</span>
                                </td>
                            </tr>
                            <ReactTooltip
                                id={"tooltipdata_4"}
                                delayHide={200}
                                place="right"
                                type="warning"
                                effect="solid"
                                clickable="true"
                            >
                                <div className="bg-tooltip">
                                    <p className="tooltip-content">
                                        <strong>Last trade price:</strong> The price at which the warrant was last
                                        traded. Note: Warrant do not trade every day, this has implications when
                                        calculating price changes. <Link
                                        to="/edu/monitoring-price-changes/last-traded-price">Click here</Link> for more
                                        info.

                                    </p>
                                </div>
                            </ReactTooltip>
                            <tr>
                                <th
                                    className="ttip ttip4"
                                    data-target="mostrecenttradedate"
                                    data-hasqtip={227}
                                >
                                        <span data-tip
                                              data-for={"tooltipdata_5"}>Most recent trade date</span>
                                </th>
                                <td>
                                        <span className="val TRADE_DATE">
                                            {d.TRADE_DATE}
                                        </span>
                                </td>
                            </tr>
                            <ReactTooltip
                                id={"tooltipdata_5"}
                                delayHide={200}
                                place="right"
                                type="warning"
                                effect="solid"
                                clickable="true"
                            >
                                <div className="bg-tooltip">
                                    <p className="tooltip-content">
                                        <strong>Most recent trade date:</strong> The last date that the warrant recorded
                                        a trade. Note: Warrant do not trade every day, this has implications when
                                        calculating price changes. <Link
                                        to="/edu/monitoring-price-changes/last-traded-price">Click here</Link> for more
                                        info.

                                    </p>
                                </div>
                            </ReactTooltip>
                            <tr>
                                <th
                                    className="ttip ttip5"
                                    data-target="underlying"
                                    data-hasqtip={236}
                                >
                                        <span data-tip
                                              data-for={"tooltipdata_6"}>Underlying</span>
                                </th>
                                <td>
                                        <span className="val underlying_name">
                                            {getUnderlyingTickerDisplayName(d.underlying_ric, d.underlying_name, 1)}
                                            {/* {d.underlying_name} */}
                                        </span>
                                </td>
                            </tr>
                            <ReactTooltip
                                id={"tooltipdata_6"}
                                delayHide={200}
                                place="right"
                                type="warning"
                                effect="solid"
                                clickable="true"
                            >
                                <div className="bg-tooltip">
                                    <p className="tooltip-content">
                                        <strong>Underlying:</strong> The stock or index over which the Warrant is
                                        listed.

                                    </p>
                                </div>
                            </ReactTooltip>
                            <tr>
                                <th
                                    className="ttip ttip6"
                                    data-target="underlyingbidprice"
                                    data-hasqtip={245}
                                >
                                        <span data-tip
                                              data-for={"tooltipdata_7"}>Underlying bid price</span>
                                </th>
                                <td>
                                        <span className="val underlying_price">
                                            {d.underlying_price}
                                        </span>
                                </td>
                            </tr>
                            <ReactTooltip
                                id={"tooltipdata_7"}
                                delayHide={200}
                                place="right"
                                type="warning"
                                effect="solid"
                                clickable="true"
                            >
                                <div className="bg-tooltip">
                                    <p className="tooltip-content">
                                        <strong>Underlying bid price:</strong> The last bid price of the underlying
                                        shares.

                                    </p>
                                </div>
                            </ReactTooltip>
                            <tr>
                                <th
                                    className="ttip ttip7"
                                    data-target="type"
                                    data-hasqtip={254}
                                >
                                        <span data-tip
                                              data-for={"tooltipdata_8"}>Type</span>
                                </th>
                                <td>
                                    <span className="val type">{d.type}</span>
                                </td>
                            </tr>
                            <ReactTooltip
                                id={"tooltipdata_8"}
                                delayHide={200}
                                place="right"
                                type="warning"
                                effect="solid"
                                clickable="true"
                            >
                                <div className="bg-tooltip">
                                    <p className="tooltip-content">
                                        <strong>Type:</strong> Calls increase in value as share price rises, Puts
                                        increase in value as share price falls.

                                    </p>
                                </div>
                            </ReactTooltip>
                            <tr>
                                <th
                                    className="ttip ttip8"
                                    data-target="exerciseprice"
                                    data-hasqtip={263}
                                >
                                        <span data-tip
                                              data-for={"tooltipdata_9"}>Exercise price</span>
                                </th>
                                <td>
                                        <span className="val exercise_price">
                                            {d.exercise_price}
                                        </span>
                                </td>
                            </tr>
                            <ReactTooltip
                                id={"tooltipdata_9"}
                                delayHide={200}
                                place="right"
                                type="warning"
                                effect="solid"
                                clickable="true"
                            >
                                <div className="bg-tooltip">
                                    <p className="tooltip-content">
                                        <strong>Exercise price:</strong> The price at which you can buy (calls) or sell
                                        (puts) the underlying share or index at expiry.

                                    </p>
                                </div>
                            </ReactTooltip>
                            <tr>
                                <th
                                    className="ttip ttip9"
                                    data-target="dwps"
                                    data-hasqtip={272}
                                >
                                        <span data-tip
                                              data-for={"tooltipdata_10"}>Exercise ratio</span>
                                </th>
                                <td>
                                        <span className="val conv_ratio">
                                            {d.conv_ratio}
                                        </span>
                                </td>
                            </tr>
                            <ReactTooltip
                                id={"tooltipdata_10"}
                                delayHide={200}
                                place="right"
                                type="warning"
                                effect="solid"
                                clickable="true"
                            >
                                <div className="bg-tooltip">
                                    <p className="tooltip-content">
                                        <strong>Exercise ratio:</strong> The number of warrants that entitles you to one
                                        unit of the underlying share or index at expiry.

                                    </p>
                                </div>
                            </ReactTooltip>
                            <tr>
                                <th
                                    className="ttip ttip10"
                                    data-target="lasttradingdate"
                                    data-hasqtip={281}
                                >
                                       <span data-tip
                                             data-for={"tooltipdata_10"}> Last trading date</span>
                                </th>
                                <td>
                                        <span className="val last_trading_date">
                                            {d.last_trading_date}
                                        </span>
                                </td>
                            </tr>
                            <ReactTooltip
                                id={"tooltipdata_10"}
                                delayHide={200}
                                place="right"
                                type="warning"
                                effect="solid"
                                clickable="true"
                            >
                                <div className="bg-tooltip">
                                    <p className="tooltip-content">
                                        <strong>Last trading date:</strong> The last day to buy or sell the warrant.
                                        This date is the 2nd trading day prior to the expiry date. <Link
                                        to="/edu/single-share-calls/last-trading-day">Click here</Link> for more
                                        information.

                                    </p>
                                </div>
                            </ReactTooltip>
                            <tr>
                                <th
                                    className="ttip ttip11"
                                    data-target="expiry"
                                    data-hasqtip={290}
                                >
                                        <span data-tip
                                              data-for={"tooltipdata_11"}>Expiry</span>
                                </th>
                                <td>
                                        <span className="val maturity">
                                            {d.maturity}
                                        </span>
                                </td>
                            </tr>
                            <ReactTooltip
                                id={"tooltipdata_11"}
                                delayHide={200}
                                place="right"
                                type="warning"
                                effect="solid"
                                clickable="true"
                            >
                                <div className="bg-tooltip">
                                    <p className="tooltip-content">
                                        <strong>Expiry:</strong> The date at which the warrant will expire.
                                    </p>
                                </div>
                            </ReactTooltip>
                            <tr>
                                <th
                                    className="ttip ttip12"
                                    data-target="effectivegearing"
                                    data-hasqtip={299}
                                >
                                        <span data-tip
                                              data-for={"tooltipdata_12"}>Effective gearing (x)</span>
                                </th>
                                <td>
                                        <span className="val effective_gearing">
                                            {d.effective_gearing}
                                        </span>
                                </td>
                            </tr>
                            <ReactTooltip
                                id={"tooltipdata_12"}
                                delayHide={200}
                                place="right"
                                type="warning"
                                effect="solid"
                                clickable="true"
                            >
                                <div className="bg-tooltip">
                                    <p className="tooltip-content">
                                        <strong>Effective gearing:</strong> Indicates your leveraged exposure. An
                                        effective gearing of 5X tells you a 1% move in the underlying stock/index
                                        results in an approximate 5% move in your Warrant price. <Link
                                        to="/edu/useful-warrant-terms/effective-gearing">Click here</Link> to learn more
                                        about effective gearing.
                                    </p>
                                </div>
                            </ReactTooltip>
                            <tr>
                                <th
                                    className="ttip ttip13"
                                    data-target="delta"
                                    data-hasqtip={308}
                                >
                                        <span data-tip
                                              data-for={"tooltipdata_13"}>Delta (%)</span>
                                </th>
                                <td>
                                    <span className="val delta">{d.delta}</span>
                                </td>
                            </tr>
                            <ReactTooltip
                                id={"tooltipdata_13"}
                                delayHide={200}
                                place="right"
                                type="warning"
                                effect="solid"
                                clickable="true"
                            >
                                <div className="bg-tooltip">
                                    <p className="tooltip-content">
                                        <strong>Delta:</strong> Estimates how a warrant moves in relation to the
                                        underlying stock/index. Puts have negative deltas because their prices increase
                                        as the underlying stock/index falls. Note, if your warrant has a WPS that this
                                        will need to be taken into account.
                                    </p>
                                </div>
                            </ReactTooltip>
                            <tr>
                                <th
                                    className="ttip ttip14"
                                    data-target="sensitivity"
                                    data-hasqtip={317}
                                >
                                        <span data-tip
                                              data-for={"tooltipdata_14"}>Sensitivity</span>
                                </th>
                                <td>
                                        <span className="val sensitivity">
                                            {d.sensitivity}
                                        </span>
                                </td>
                            </tr>
                            <ReactTooltip
                                id={"tooltipdata_14"}
                                delayHide={200}
                                place="right"
                                type="warning"
                                effect="solid"
                                clickable="true"
                            >
                                <div className="bg-tooltip">
                                    <p className="tooltip-content">
                                        <strong>Sensitivity:</strong> Shows you how much the underlying stock or index
                                        must move for your warrant to move one "tick" or "Spread". For warrants that
                                        cost less than RM1, one tick is RM0.005.
                                    </p>
                                </div>
                            </ReactTooltip>
                            <tr>
                                <th
                                    className="ttip ttip15"
                                    data-target="breakevenpriceatexpiry"
                                    data-hasqtip={326}
                                >
                                        <span data-tip
                                              data-for={"tooltipdata_15"}>Break even price at expiry</span>
                                </th>
                                <td>
                                        <span className="val breakeven_price">
                                            {d.breakeven_price}
                                        </span>
                                </td>
                            </tr>
                            <ReactTooltip
                                id={"tooltipdata_15"}
                                delayHide={200}
                                place="right"
                                type="warning"
                                effect="solid"
                                clickable="true"
                            >
                                <div className="bg-tooltip">
                                    <p className="tooltip-content">
                                        <strong>Break even price at expiry:</strong> If you are still holding the
                                        warrant at expiry, this is the price that the shares/index must exceed (fall
                                        below) for calls (puts) for you to make a profit on your purchase price. <Link
                                        to="/edu/useful-warrant-terms/break-even-price">Click here</Link> here for a
                                        more detailed description.
                                    </p>
                                </div>
                            </ReactTooltip>
                            <tr>
                                <th
                                    className="ttip ttip16"
                                    data-target="impliedvolatility"
                                    data-hasqtip={335}
                                >
                                        <span data-tip
                                              data-for={"tooltipdata_16"}>Implied volatility (%)</span>
                                </th>
                                <td>
                                        <span className="val implied_volatility">
                                            {d.implied_volatility}
                                        </span>
                                </td>
                            </tr>
                            <ReactTooltip
                                id={"tooltipdata_16"}
                                delayHide={200}
                                place="right"
                                type="warning"
                                effect="solid"
                                clickable="true"
                            >
                                <div className="bg-tooltip">
                                    <p className="tooltip-content">
                                        <strong>Implied volatility:</strong> The higher the implied volatility, the
                                        higher the price of the Warrant (all other factors held constant). <Link
                                        to="/edu/useful-warrant-terms/implied-volatility">Click here</Link> to learn
                                        more about implied volatility.
                                    </p>
                                </div>
                            </ReactTooltip>
                            <tr>
                                <th
                                    className="ttip ttip17"
                                    data-target="theta"
                                    data-hasqtip={344}
                                >
                                        <span data-tip
                                              data-for={"tooltipdata_17"}>Theta (%)</span>
                                </th>
                                <td>
                                    <span className="val theta">{d.theta}</span>
                                </td>
                            </tr>
                            <ReactTooltip
                                id={"tooltipdata_17"}
                                delayHide={200}
                                place="right"
                                type="warning"
                                effect="solid"
                                clickable="true"
                            >
                                <div className="bg-tooltip">
                                    <p className="tooltip-content">
                                        <strong>Theta:</strong> The daily time decay of the warrant expressed in
                                        percentage terms. <Link to="/edu/understanding-warrant-structure/time-decay">Click
                                        here</Link> to learn more about Theta.
                                    </p>
                                </div>
                            </ReactTooltip>
                            <tr>
                                <th
                                    className="ttip ttip18"
                                    data-target="moneyness"
                                    data-hasqtip={353}
                                >
                                        <span data-tip
                                              data-for={"tooltipdata_18"}>Moneyness</span>
                                </th>
                                <td>
                                        <span className={`val moneyness ${d.moneyness === "2" ? "down" : "up"}`}>
                                            {d.percent_moneyness}% {d.moneyness === "2" ? "OTM" : "ITM"}
                                        </span>
                                </td>
                            </tr>
                            <ReactTooltip
                                id={"tooltipdata_18"}
                                delayHide={200}
                                place="right"
                                type="warning"
                                effect="solid"
                                clickable="true"
                            >
                                <div className="bg-tooltip">
                                    <p className="tooltip-content">
                                        <strong>Moneyness:</strong> ITM (in-the-money), OTM (out-of-the-money), ATM
                                        (at-the-money). <Link to="/edu/understanding-warrant-structure/moneyness">Click
                                        here</Link> to learn more about moneyness.
                                    </p>
                                </div>
                            </ReactTooltip>
                            </tbody>
                        </table>
                        <button id={"quickTermsSheetButton"} onClick={() => changeTermSheetLink(d)}>Term sheet</button>
                    </div>
                </div>
            )[index]}
        </>
    )
}

export default QuickTerms
